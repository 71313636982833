import React from "react";
import { navigate } from "gatsby";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import AppConfig from '../appconfig';
import RegisterForm from './registerForm';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import {
	setLoggedIn,
	reset,
	addRoomData,
	setAllowDm,
	setBlockedUsernames,
	setAvatarUrl,
	setShowSignInModal,
	setDmSounds,
	setPlusMember
} from "../state/actions";
import { FormattedMessage, injectIntl } from 'react-intl';
import DatePicker from "react-datepicker";
import FloatingLabel from 'react-bootstrap/FloatingLabel';


class SignInModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			passwordInvalid: "",
			usernameInvalid: "",
			signUpFeedback: "",
			signInFeedback: "",
			isLoading: false,
			showRegisterForm: false,
			selectedDate: ''
		};

		this.handleSignUp = this.handleSignUp.bind(this);
		this.setIsLoading = this.setIsLoading.bind(this);
		this.setPasswordInvalid = this.setPasswordInvalid.bind(this);
		this.setUsernameInvalid = this.setUsernameInvalid.bind(this);
		this.setSignUpFeedback = this.setSignUpFeedback.bind(this);
		this.setSignInFeedback = this.setSignInFeedback.bind(this);
		this.registerUser = this.registerUser.bind(this);
		this.handleSignIn = this.handleSignIn.bind(this);
		this.signIn = this.signIn.bind(this);
		this.loginUser = this.loginUser.bind(this);
		this.handleForgotPassClick = this.handleForgotPassClick.bind(this);
		this.setSelectedDate = this.setSelectedDate.bind(this);
	}



	handleSignUp(event) {
		if (this.state.isLoading) {
			return;
		}

		const form = event.currentTarget;

		// Validate username
		let username = form.elements.username.value;
		var regex = /^[a-zA-Z0-9.\-_$@*!]{3,30}$/;
		if (!regex.test(username)) {
			this.setUsernameInvalid("true");
			event.preventDefault();
			event.stopPropagation();
			return;
		}

		// The user wants to sign up, check sign up inputs and register the user.
		if (form.elements.registerPassword.value != form.elements.registerPasswordConfirm.value) {
			this.setPasswordInvalid("true");
			event.preventDefault();
			event.stopPropagation();
			return;
		} else {
			this.setIsLoading(true);
			this.setPasswordInvalid("");
			this.registerUser(username, form.elements.registerEmail.value, form.elements.registerPassword.value);
		}

		event.preventDefault();
		event.stopPropagation();
	}

	registerUser(username, email, password) {
		let lang = 'sv';
		if (this.props.langUrl == undefined || this.props.langUrl == '') {
			lang = 'en';
		}
		fetch(AppConfig.API_URL + 'register/' + email + '/' + password + '/' + username, {
			credentials: 'include'
		})
			.then(res => res.json())
			.then(
				result => {
					if (result.success) {
						this.signIn(email, password);
						//navigate('/activate');
						this.props.setShowSignInModal(false);
						return;
					} else {
						let feedback = this.props.intl.formatMessage({ id: 'couldNotSignup' });
						if (result.errorCode == 2) {
							feedback = this.props.intl.formatMessage({ id: 'alreadyHaveAccount' });
						} else if (result.errorCode == 3) {
							feedback = this.props.intl.formatMessage({ id: 'passwordMustContain' });
						} else if (result.errorCode == 4) {
							feedback = this.props.intl.formatMessage({ id: 'usernameTaken' });
						} else if (result.errorCode == 5) {
							navigate(this.props.langUrl + '/badIp');
							this.props.setShowSignInModal(false);
							return;
						} else if (result.errorCode == 6) {
							feedback = this.props.intl.formatMessage({ id: 'usernameNotAllowed' });
						} else if (result.errorCode == 109) {
							// User was underage
							feedback = this.props.intl.formatMessage({ id: 'needToBeOlder' });
						}

						this.setSignUpFeedback(feedback);
						var timer = setTimeout(function () {
							this.setSignUpFeedback('');
							clearTimeout(timer);
						}.bind(this), 5000);
					}
					this.setIsLoading(false);
				},
				error => {
					console.log("Failure");
					this.setIsLoading(false);
					this.setSignUpFeedback(this.props.intl.formatMessage({ id: 'couldNotSignup' }));
					var timer = setTimeout(function () {
						this.setSignUpFeedback('');
						clearTimeout(timer);
					}.bind(this), 5000);
				}
			);
	}

	handleSignIn(event) {
		if (this.state.isLoading) {
			return;
		}

		this.setIsLoading(true);

		event.preventDefault();
		event.stopPropagation();

		const form = event.currentTarget;

		this.signIn(form.elements.loginEmail.value, form.elements.loginPassword.value);
	}

	signIn(email, password) {
		fetch(AppConfig.API_URL + 'login/' + email + '/' + password, {
			credentials: 'include'
		})
			.then(res => res.json())
			.then(
				result => {
					if (result.success) {
						this.loginUser(result);
					} else {
						if (result.errorCode == 1) {
							this.setSignInFeedback(this.props.intl.formatMessage({ id: 'wrongMailOrPass' }));
							var timer = setTimeout(function () {
								this.setSignInFeedback('');
								clearTimeout(timer);
							}.bind(this), 5000)
						} else if (result.errorCode == 2) {
							navigate(this.props.langUrl + '/badIp');
							this.props.setShowSignInModal(false);
							return;
						} else if (result.errorCode == 3) {
							this.setSignInFeedback(this.props.intl.formatMessage({ id: 'accountNotActivated' }));
						} else {
							this.setSignInFeedback(this.props.intl.formatMessage({ id: 'couldNotLogin' }));
						}
					}
					this.setIsLoading(false);
				},
				error => {
					console.log("Failure");
					this.setIsLoading(false);
					this.setSignInFeedback(this.props.intl.formatMessage({ id: 'couldNotLogin' }));
					var timer = setTimeout(function () {
						this.setSignInFeedback('');
						clearTimeout(timer);
					}.bind(this), 5000);
				}
			);
	}

	loginUser(data) {
		let date = new Date();
		date = date.addDays(90);

		localStorage.setItem("userId", data.userId);
		localStorage.setItem("username", data.username);
		localStorage.setItem("sessionId", data.sessionId);

		//this.props.connectWebSocket(data.userId, data.sessionId, data.username);
		this.props.setAllowDm(data.allowDm);
		this.props.setBlockedUsernames(data.blockedUsernames);
		this.props.setAvatarUrl(data.avatarUrl)
		this.props.onHide();
		this.props.setLoggedIn(true);
		this.props.setDmSounds(data.dmSounds);
		this.props.setPlusMember(data.plusMember);
	}

	setIsLoading(value) {
		this.setState({
			isLoading: value
		});
	}

	setPasswordInvalid(value) {
		this.setState({
			passwordInvalid: value
		});
	}

	setUsernameInvalid(value) {
		this.setState({
			usernameInvalid: value
		});
	}

	setSignUpFeedback(value) {
		this.setState({
			signUpFeedback: value
		});
	}

	setSignInFeedback(value) {
		this.setState({
			signInFeedback: value
		});
	}

	setSelectedDate(selectedDate) {
		this.setState({
			selectedDate: selectedDate
		});
	}

	handleForgotPassClick() {
		navigate('/forgotPassword');
		this.props.onHide();
	}

	render() {
		if (this.props.showRegister) {
			return (
				<Modal
					show={this.props.show}
					onHide={this.props.onHide}
					size="sm"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>

					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							<FormattedMessage id="enter" />
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<Form onSubmit={this.handleSignUp}>

							<Form.Group>
								<FloatingLabel
									controlId="registerUsername"
									label="Användarnamn"
									className="mb-3"
								>
									<Form.Control required minLength="3" maxLength="30" type="text" name="username" defaultValue={this.props.globalState.enteredName}
										isInvalid={this.state.usernameInvalid} />
									<Form.Control.Feedback type="invalid">
										<FormattedMessage id="usernameMustBe" />
									</Form.Control.Feedback>
								</FloatingLabel>
							</Form.Group>

							<RegisterForm showForm={true} passwordInvalid={this.state.passwordInvalid} />

							<Form.Group controlId="formBasicCheckbox">
								<Form.Check required type="checkbox" label={this.props.intl.formatMessage({ id: 'readAndAccept' })} />
								<a target="_blank" href="/terms">Terms of Service</a> <FormattedMessage id="and" />
								<a target="_blank" href="/privacy"> Privacy policy</a>
							</Form.Group>

							<p className="feedback">{this.state.signUpFeedback}</p>

							<EnterButton isLoading={this.state.isLoading} />
						</Form>

					</Modal.Body>

				</Modal>
			);
		} else {
			return (
				<Modal
					show={this.props.show}
					onHide={this.props.onHide}
					size="sm"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>

					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							<FormattedMessage id="login" />
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>

						<Form onSubmit={this.handleSignIn}>

							<Form.Group controlId="validationCustomEmail">
								<FloatingLabel
									controlId="loginEmail"
									label="E-postadress"
									className="mb-3"
								>
									<Form.Control required type="email" name="loginEmail" placeholder="" />
									<Form.Control.Feedback type="invalid">
										Incorrect
									</Form.Control.Feedback>
								</FloatingLabel>
							</Form.Group>

							<Form.Group controlId="validationCustom04">
								<FloatingLabel
									controlId="loginPassword"
									label="Lösenord"
									className="mb-3"
								>
									<Form.Control name="loginPassword" type="password" placeholder="" required />
									<Form.Control.Feedback type="invalid">
										Incorrect
									</Form.Control.Feedback>
								</FloatingLabel>
							</Form.Group>

							<p className="feedback">{this.state.signInFeedback}</p>
							<Button type="submit" disabled={this.state.isLoading}>
								{this.state.isLoading ? <FormattedMessage id="loggingIn" /> : <FormattedMessage id="login" />}
							</Button>
						</Form>

					</Modal.Body>
					<Modal.Footer className={'signInFooter'}>
						<Button variant="outline-primary" onClick={this.handleForgotPassClick}><FormattedMessage id="forgotPassword" /></Button>
						<Button variant="outline-primary" onClick={() => this.props.setShowRegister(true)}><FormattedMessage id="notMember" />?</Button>
					</Modal.Footer>
				</Modal>
			);
		}
	}
}



function mapStateToProps(state) {
	const { loggedIn, messages } = state
	return {
		loggedIn: loggedIn,
		globalState: state
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		setLoggedIn: setLoggedIn,
		reset: reset,
		addRoomData: addRoomData,
		setAllowDm: setAllowDm,
		setBlockedUsernames: setBlockedUsernames,
		setAvatarUrl: setAvatarUrl,
		setShowSignInModal: setShowSignInModal,
		setDmSounds: setDmSounds,
		setPlusMember: setPlusMember
	},
		dispatch
	);
}

export default injectIntl(connect(
	mapStateToProps,
	mapDispatchToProps
)(SignInModal));




export function EnterButton(props) {
	return (
		<Button type="submit" className="enterBtn" disabled={props.isLoading}>
			{props.isLoading ? <FormattedMessage id="entering" /> : <FormattedMessage id="enter" />}
		</Button>
	);
}


Date.prototype.addDays = function (days) {
	let date = new Date(this.valueOf());
	date.setDate(date.getDate() + days);
	return date;
}