import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointUp } from '@fortawesome/free-regular-svg-icons'
import PostContentInner from './postContentInner';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import SEO from "../components/seo";
import {
  setLoggedIn,
  reset,
  setShowSignInModal
} from "../state/actions";
import { FormattedMessage, injectIntl } from 'react-intl';



class PostContent extends React.Component {

    render() {
        return (
            <PostContentInner postTitle={this.props.postTitle} langUrl={this.props.langUrl} />
        );
    }
}

function mapStateToProps(state) {
    const { loggedIn, initialLoader } = state
    return { loggedIn: loggedIn,
            initialLoader: initialLoader }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setLoggedIn: setLoggedIn,
        reset: reset,
        setShowSignInModal: setShowSignInModal
    },
    dispatch
    );
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(PostContent));

