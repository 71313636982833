export const reset = () => {
  return {
    type: "RESET"
  };
};

export const setLoggedIn = value => {
  return {
    type: "SET_LOGGED_IN",
    payload: value
  };
};

export const setAvatarUrl = value => {
  return {
    type: "SET_AVATAR_URL",
    payload: value
  };
};

export const setInitialLoader = value => {
  return {
    type: "SET_INITIAL_LOADER",
    payload: value
  };
};

export const setTotalUnread = value => {
  return {
    type: "SET_TOTAL_UNREAD",
    payload: value
  };
};


export const setMobileDrawerOpen = value => {
  return {
    type: "SET_MOBILE_DRAWER_OPEN",
    payload: value
  };
};

export const setRooms = value => {
  return {
    type: "SET_ROOMS",
    payload: value
  };
};

export const setConversations = value => {
  return {
    type: "SET_CONVERSATIONS",
    payload: value
  };
};

export const addRoom = value => {
  return {
    type: "ADD_ROOM",
    payload: value
  };
};

export const removeRoom = value => {
  return {
    type: "REMOVE_ROOM",
    payload: value
  };
};

export const addConversation = value => {
  return {
    type: "ADD_CONVERSATION",
    payload: value
  };
};

export const updateConversation = (indexToUpdate, newConversation) => {
  return {
    type: "UPDATE_CONVERSATION",
    indexToUpdate: indexToUpdate,
    newConversation: newConversation
  };
};

export const removeConversation = value => {
  return {
    type: "REMOVE_CONVERSATION",
    payload: value
  };
};

export const addRoomData = (roomStateName, data) => {
  return {
    type: "ADD_ROOM_DATA",
    roomStateName: roomStateName,
    data: data
  };
};

export const removeRoomData = (roomStateName) => {
  return {
    type: "REMOVE_ROOM_DATA",
    roomStateName: roomStateName
  };
};

export const updateRoomData = (roomStateName, valueName, value) => {
  return {
    type: "UPDATE_ROOM_DATA",
    roomStateName: roomStateName,
    valueName: valueName,
    value: value
  };
};


export const addOrUpdateRoomData = (roomStateName, roomData) => {
  return {
    type: "ADD_OR_UPDATE_ROOM_DATA",
    roomStateName: roomStateName,
    roomData: roomData
  };
};

export const addConversationData = (conversationStateName, data) => {
  return {
    type: "ADD_CONVERSATION_DATA",
    conversationStateName: conversationStateName,
    data: data
  };
};

export const updateConversationData = (conversationStateName, valueName, value) => {
  return {
    type: "UPDATE_CONVERSATION_DATA",
    conversationStateName: conversationStateName,
    valueName: valueName,
    value: value
  };
};

export const updateMessageInConversationData = (conversationStateName, messageId, valueName, value) => {
  return {
    type: "UPDATE_MESSAGE_IN_CONVERSATION_DATA",
    conversationStateName: conversationStateName,
    messageId: messageId,
    valueName: valueName,
    value: value
  };
};

export const addOrUpdateConversationData = (conversationStateName, conversationData) => {
  return {
    type: "ADD_OR_UPDATE_CONVERSATION_DATA",
    conversationStateName: conversationStateName,
    conversationData: conversationData
  };
};

export const refreshStateData = (stateName) => {
  return {
    type: "REFRESH_STATE_DATA",
    stateName: stateName,
  };
};

export const setUnread = (unreadStateName, data) => {
  return {
    type: "SET_UNREAD",
    unreadStateName: unreadStateName,
    data: data
  };
};

export const addUnread = (unreadStateName, data) => {
  return {
    type: "ADD_UNREAD",
    unreadStateName: unreadStateName,
    data: data
  };
};

export const addToTotalUnread = (data) => {
  return {
    type: "ADD_TO_TOTAL_UNREAD",
    data: data
  };
};

export const reduceTotalUnread = (unreadStateName) => {
  return {
    type: "REDUCE_TOTAL_UNREAD",
    unreadStateName: unreadStateName
  };
};

export const resetUnread = (unreadStateName) => {
  return {
    type: "RESET_UNREAD",
    unreadStateName: unreadStateName
  };
};

export const setAllowDm = value => {
  return {
    type: "SET_ALLOW_DM",
    payload: value
  };
};

export const setDmSounds = value => {
  return {
    type: "SET_DM_SOUNDS",
    payload: value
  };
};

export const setBlockedUsernames = value => {
  return {
    type: "SET_BLOCKED_USERNAMES",
    payload: value
  };
};

export const addBlockedUsername = value => {
  return {
    type: "ADD_BLOCKED_USERNAME",
    payload: value
  };
};

export const removeBlockedUsername = value => {
  return {
    type: "REMOVE_BLOCKED_USERNAME",
    payload: value
  };
};

export const setShowSignInModal = value => {
  return {
    type: "SET_SHOW_SIGN_IN_MODAL",
    payload: value
  };
};

export const removeUserMsgs = (stateName, value) => {
  return {
    type: "REMOVE_USER_MSGS",
    payload: value,
    stateName: stateName
  };
};

export const removeMessage = (stateName, value) => {
  return {
    type: "REMOVE_MESSAGE",
    payload: value,
    stateName: stateName
  };
};

export const setSelectedLang = value => {
  return {
    type: "SET_SELECTED_LANG",
    payload: value
  };
};

export const setPlusMember = value => {
  return {
    type: "SET_PLUS_MEMBER",
    payload: value
  };
};

export const setEnteredName = value => {
  return {
    type: "SET_ENTERED_NAME",
    payload: value
  };
};

export const setUnavailable = value => {
  return {
    type: "SET_UNAVAILABLE",
    payload: value
  };
};

export const setClientFp = value => {
  return {
    type: "SET_CLIENT_FP",
    payload: value
  };
};

export const setIsGuest = value => {
  return {
    type: "SET_IS_GUEST",
    payload: value
  };
};