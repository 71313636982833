import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Badge from 'react-bootstrap/Badge';
import {
  setLoggedIn,
  setMobileDrawerOpen
} from "../state/actions";


class DrawerButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			
		};
	}

 	render() {

		return (
			<button onClick={() => this.props.setMobileDrawerOpen(!this.props.mobileDrawerOpen)} type="button" className="navbar-toggler collapsed drawerToggler">
				<span className="navbar-toggler-icon"></span>
			</button>
		);
	 }
}

function mapStateToProps(state) {
	const { loggedIn, mobileDrawerOpen, totalUnread, initialLoader } = state
	return { 
		loggedIn: loggedIn,
		mobileDrawerOpen: mobileDrawerOpen,
		totalUnread: totalUnread,
		initialLoader: initialLoader
	}
}

function mapDispatchToProps(dispatch) {
	  return bindActionCreators(
    {
      setLoggedIn: setLoggedIn,
      setMobileDrawerOpen: setMobileDrawerOpen
    },
    dispatch
  );}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DrawerButton);