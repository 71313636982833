import React, { useState, useRef, createRef } from "react"
import { navigate } from 'gatsby';
import UserMenu from "./userMenu";
import AppConfig from '../appconfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown, faReplyAll } from '@fortawesome/free-solid-svg-icons'
import anonUser from '../../static/anon_user.png';

//import YoutubePlayer from 'react-player/youtube'
//import FilePlayer from 'react-player/file'
//import ReactPlayer from 'react-player'
import ReactPlayer from 'react-player/lazy'

// For all languages
import moment from'moment/min/moment-with-locales';
//import moment from'moment';
//import 'moment/locale/sv';

import VoteWrapper from "./voteWrapper";


class Comment extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			votedUp: this.props.votedUp,
			votedDown: this.props.votedDown,
			score: this.props.score,
			loadingError: false,
			show: false,
			showMoreReplies: (this.props.replies != undefined && this.props.replies.length == 3) ? true : false
		};

		this.target = createRef(null);

		this.vote = this.vote.bind(this);
		this.setShow = this.setShow.bind(this);
		this.resetVote = this.resetVote.bind(this);
		this.getReplies = this.getReplies.bind(this);
	}

	getReplies(commentId) {
		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let messagesWrapper = document.getElementsByClassName('msgWrapper')[0];

		this.setState({
			scrollPosition: messagesWrapper.scrollHeight
		});

		let url = AppConfig.API_URL + 'getReplies';

		let ids = [];

		for (var i = 0; i < this.props.replies.length; i++) {
			let reply = this.props.replies[i];
			ids.push(reply.id)
		}

		fetch(url, {
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				userId: userId, 
				sessionId: sessionId,
				parentId: commentId,
				fetchedIds: ids,
			})
		})
		.then(res=>res.json())
		.then(
			result => {
				if (result.success) {
					// Add the replies to the parent's comment list
					this.props.setReplies(commentId, result.comments)

					this.setState({
						showMoreReplies: result.comments.length == 10 ? true : false
					});
				} else {
					let errorMsg = "Något gick fel. Försök igen senare."
					if (result.errorCode == 101) {
						window.location.reload();
					} else {
						this.setState({
							showInfoModal: true,
							infoModalText: errorMsg
						});
					}
				}
			},
			error => {
				console.log("Error");
			}
		);

		return false; 		
	}

	vote(event, voteType) {
		event.preventDefault();
  		event.stopPropagation();
  		
  		let scoreUpdate = 1;
		if (voteType == -1) {
			// The user voted down.
			if (this.state.votedDown != undefined) {
				// Already voted down. Do nothing.
				return false;
			}

			if (this.state.votedUp != undefined) {
				// Already voted up. Reduce the score by 2.
				scoreUpdate = 2;
			}

			this.setState({
				votedUp: undefined,
				votedDown: faThumbsDown,
				score: this.state.score-scoreUpdate
			});
		} else {
			// This user voted up.
			if (this.state.votedUp != undefined) {
				// Already voted up. Do nothing.
				return false;
			}

			if (this.state.votedDown != undefined) {
				// Already voted down. Increase the score by 2.
				scoreUpdate = 2;
			}
			this.setState({
				votedUp: faThumbsUp,
				votedDown: undefined,
				score: this.state.score+scoreUpdate
			});
		}

		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'castCommentVote';

		fetch(url, {
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				userId: userId,
				sessionId: sessionId,
				postId: this.props.postId,
				scoreVal: voteType,
				commentId: this.props.id
			})
		})
		.then(res=>res.json())
		.then(
			result => {
				if (result.success) {
					// Do nothing
				} else {
					if (result.errorCode == 6) {
						alert('Det är inte tillåtet att rösta ned under 0');
					} else if (result.errorCode == 101) {
						window.location.reload();
					}

					this.resetVote(voteType);
				}
			},
			error => {
				console.log("Internal error");
			}
		);

		return false; 	
	}

	resetVote(voteType) {
		if (voteType == -1) {
			// The user voted down.

			this.setState({
				votedUp: undefined,
				votedDown: undefined,
				score: this.state.score+1
			});
		} else {
			// This user voted up.
			
			this.setState({
				votedUp: undefined,
				votedDown: undefined,
				score: this.state.score-1
			});
		}
	}

	setShow(value) {
		this.setState({
			show: value
		});
	};


	render() {
		let replyComments = [];

 		let replies = this.props.replies;

 		if (replies != undefined && replies.length > 0) {
 			for (let i = 0; i < replies.length; i++) {
	 			let comment = replies[i];
	 			
	 			let imgSrc = comment.avatarUrl;
				if (comment.avatarUrl == undefined || comment.avatarUrl == '') {
					imgSrc = anonUser;
				}

				replyComments.push(
					<Comment key={comment.id+"cmt"}
						id={comment.id}
						respondId={this.props.id}
						text={comment.text}
						time={comment.created}
						userId={comment.userId}
						username={comment.username}
						type={comment.type}
						mediaType={comment.mediaType}
						addBlockedUsername={this.props.addBlockedUsername}
						removeBlockedUsername={this.props.removeBlockedUsername}
						avatar={imgSrc}
						score={comment.score}
						postId={comment.postId}
						pending={comment.pending}
						votedDown={comment.userVote == -1 ? faThumbsDown : undefined}
						votedUp={comment.userVote == 1 ? faThumbsUp : undefined} 
						setRespondData={this.setRespondData}
						handleImgClick={this.props.handleImgClick}
					/>
				);
			}
 		}

		let date = moment(this.props.time);

		// Only set swedish locale for now. Remove the if statement and import above to support all.
		var locale = window.navigator.userLanguage || window.navigator.language;
		date.locale(locale);

		let msgBody = (
			<div className="msgBody">
				{this.props.text}
			</div>
		);
		
		if (this.props.mediaType == 2 || this.props.mediaType == 3) {
			if (this.state.loadingError) {
				msgBody = (
					<div className="imageNotLoadedText">Image could not be loaded</div>
				);
			}
			
			msgBody = (
				<img src={this.props.text} onClick={((e) => this.props.handleImgClick(e, this.props.text))} alt="" />
			);
		} else if (this.props.mediaType  == 4) {
			msgBody = (
				<div className="msgBody video">
					<ReactPlayer className='react-player' height="250px" width="100%" controls={true} url={this.props.text} />
				</div>
			);
		} else if (this.props.mediaType == 5) {
			msgBody = (
				<div className="msgBody audio">
					<audio src={this.props.text} controls="controls" controlsList="nodownload" />
				</div>
			);
		}

		return (
			<div className="commentOuter">
				<div className="comment">
					<VoteWrapper vote={this.vote} votedUp={this.state.votedUp} votedDown={this.state.votedDown} score={this.state.score} />
					
					<div className="commentContent">
						<div className="msgTitle">
							<div className="msgAvatar" ref={this.target} onClick={() => this.setShow(!this.state.show)}>
								<img src={this.props.avatar} className="rounded-circle userImg" />
							</div>
							
							<div className="msgUser">
								<div ref={this.target} onClick={() => this.setShow(!this.state.show)}>{this.props.username}</div>
							</div>
							<span className="msgTime">{date.fromNow()}</span>
						</div>

						<UserMenu show={this.state.show} setShow={this.setShow} username={this.props.username} target={this.target} 
							addBlockedUsername={this.props.addBlockedUsername} removeBlockedUsername={this.props.removeBlockedUsername} placement={"right"} roomId={this.props.roomId} />
							
						<div className="msgContainer">						

							<div className={this.props.pending ? 'msgBody lightGrey' : 'msgBody'}>
								{msgBody}
							</div>
						</div>
					</div>

					<div className="reply" onClick={() => this.props.setRespondData(this.props.username, this.props.respondId)}>
						<FontAwesomeIcon icon={faReplyAll} />
						<span className=""> Svara</span>
					</div>
				</div>

				<div className="replies">
					{replyComments}
					{this.state.showMoreReplies &&
						<div className="showMoreReplies" onClick={() => this.getReplies(this.props.id)}>
							Visa fler svar
						</div>
					}
				</div>
			</div>
		);
	}
	
}


export default Comment;