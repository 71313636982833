import React from "react"
import Form from 'react-bootstrap/Form';
import { FormattedMessage, injectIntl } from 'react-intl';
import FloatingLabel from 'react-bootstrap/FloatingLabel';


function RegisterForm(props) {

	if (props.showForm) {
		return (
			<div>
				<Form.Group>
					<FloatingLabel
						controlId="registerEmail"
						label="E-postadress"
						className="mb-3"
						>
						<Form.Control required type="email" name="registerEmail" />
						<Form.Control.Feedback type="invalid">
							<FormattedMessage id="enterValidMail" />
						</Form.Control.Feedback>
					</FloatingLabel>
				</Form.Group>

				<Form.Group controlId="validationCustom04">
					<FloatingLabel
						controlId="registerPassword"
						label="Lösenord"
						className="mb-3"
						>
						<Form.Control name="registerPassword" type="password" minLength="8" required />
						<Form.Control.Feedback type="invalid">
							<FormattedMessage id="choosePassword" />
						</Form.Control.Feedback>
					</FloatingLabel>
				</Form.Group>
				<Form.Group controlId="validationCustom04">
					<FloatingLabel
						controlId="registerPasswordConfirm"
						label="Bekräfta lösenord"
						className="mb-3"
						>
						<Form.Control name="registerPasswordConfirm" isInvalid={props.passwordInvalid} type="password" required />
						<Form.Control.Feedback type="invalid">
							<FormattedMessage id="passwordNoMatch" />
						</Form.Control.Feedback>
					</FloatingLabel>
				</Form.Group>
				
			</div>
		);
	} else {
		return null;
	}
	
}


export default injectIntl(RegisterForm);