import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faDotCircle } from '@fortawesome/free-regular-svg-icons'
import MicRecorder from 'mic-recorder-to-mp3';
import AppConfig from '../appconfig';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });


export default class AudioRecorder extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isRecording: false,
			isBlocked: true
		}

		this.start = this.start.bind(this);
		this.stop = this.stop.bind(this);
		this.handleRecordClick = this.handleRecordClick.bind(this);
		this.checkPermission = this.checkPermission.bind(this);
	}


	checkPermission() {
		/*
		navigator.getUserMedia({ audio: true },
			() => {
				// The permission was granted, start the recording.
				this.setState({ isBlocked: false });
				this.start();
			},
			() => {
				// The permission was denied, show error message.
				this.props.openInfoModal(this.props.permissionText);
				this.setState({ isBlocked: true })
			},
		);
		*/

		navigator.mediaDevices.getUserMedia({ audio: true })
		.then(function(mediaStream) {
			// The permission was granted, start the recording.
			this.setState({ isBlocked: false });
			this.start();
		}.bind(this))
		.catch(function(err) {
			// The permission was denied, show error message.
			this.props.openInfoModal(this.props.permissionText);
			this.setState({ isBlocked: true })
		}.bind(this));

	}

	handleRecordClick() {
		if (this.state.isRecording) {
			this.stop();
		} else {
			this.start();
		}
 	}


	start(){
		if (this.state.isBlocked) {
			// The permission was denied or has not been checked yet, check permission.
			this.checkPermission();
		} else {
			
			Mp3Recorder
				.start()
				.then(() => {
					this.setState({ isRecording: true });
					this.setState({ isRecording: true });
				}).catch((e) => console.error(e));
		}
	}

	stop(){
		Mp3Recorder
			.stop()
			.getMp3()
			.then(([buffer, blob]) => {
				const blobURL = URL.createObjectURL(blob)
				this.setState({ pressed: false, isRecording: false });

				const file = new File(buffer, 'recording.mp3', {
					type: blob.type,
					lastModified: Date.now()
				});

				this.props.onVoiceRecord(file);

			}).catch((e) => console.log(e));
	}


 	render() {
		return (
			<div className="audioRecorder" onClick={this.handleRecordClick}>
				<div className={this.state.isRecording ? 'recording' : ''}>
					<FontAwesomeIcon icon={faCircle} />
				</div>
			</div>
		);		
	 }
}

