exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-account-sv-js": () => import("./../../../src/pages/account.sv.js" /* webpackChunkName: "component---src-pages-account-sv-js" */),
  "component---src-pages-activate-js": () => import("./../../../src/pages/activate.js" /* webpackChunkName: "component---src-pages-activate-js" */),
  "component---src-pages-bad-ip-js": () => import("./../../../src/pages/badIp.js" /* webpackChunkName: "component---src-pages-bad-ip-js" */),
  "component---src-pages-bad-ip-sv-js": () => import("./../../../src/pages/badIp.sv.js" /* webpackChunkName: "component---src-pages-bad-ip-sv-js" */),
  "component---src-pages-cancelled-js": () => import("./../../../src/pages/cancelled.js" /* webpackChunkName: "component---src-pages-cancelled-js" */),
  "component---src-pages-cancelled-sv-js": () => import("./../../../src/pages/cancelled.sv.js" /* webpackChunkName: "component---src-pages-cancelled-sv-js" */),
  "component---src-pages-create-post-js": () => import("./../../../src/pages/createPost.js" /* webpackChunkName: "component---src-pages-create-post-js" */),
  "component---src-pages-discussed-js": () => import("./../../../src/pages/discussed.js" /* webpackChunkName: "component---src-pages-discussed-js" */),
  "component---src-pages-election-js": () => import("./../../../src/pages/election.js" /* webpackChunkName: "component---src-pages-election-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgotPassword.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-images-js": () => import("./../../../src/pages/images.js" /* webpackChunkName: "component---src-pages-images-js" */),
  "component---src-pages-images-sv-js": () => import("./../../../src/pages/images.sv.js" /* webpackChunkName: "component---src-pages-images-sv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-js": () => import("./../../../src/pages/latest.js" /* webpackChunkName: "component---src-pages-latest-js" */),
  "component---src-pages-liked-js": () => import("./../../../src/pages/liked.js" /* webpackChunkName: "component---src-pages-liked-js" */),
  "component---src-pages-message-js": () => import("./../../../src/pages/message.js" /* webpackChunkName: "component---src-pages-message-js" */),
  "component---src-pages-message-sv-js": () => import("./../../../src/pages/message.sv.js" /* webpackChunkName: "component---src-pages-message-sv-js" */),
  "component---src-pages-my-ads-js": () => import("./../../../src/pages/my-ads.js" /* webpackChunkName: "component---src-pages-my-ads-js" */),
  "component---src-pages-my-ads-sv-js": () => import("./../../../src/pages/my-ads.sv.js" /* webpackChunkName: "component---src-pages-my-ads-sv-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-sv-js": () => import("./../../../src/pages/news.sv.js" /* webpackChunkName: "component---src-pages-news-sv-js" */),
  "component---src-pages-parties-js": () => import("./../../../src/pages/parties.js" /* webpackChunkName: "component---src-pages-parties-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-people-sv-js": () => import("./../../../src/pages/people.sv.js" /* webpackChunkName: "component---src-pages-people-sv-js" */),
  "component---src-pages-post-js": () => import("./../../../src/pages/post.js" /* webpackChunkName: "component---src-pages-post-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/resetPassword.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-roleplay-js": () => import("./../../../src/pages/roleplay.js" /* webpackChunkName: "component---src-pages-roleplay-js" */),
  "component---src-pages-roleplay-sv-js": () => import("./../../../src/pages/roleplay.sv.js" /* webpackChunkName: "component---src-pages-roleplay-sv-js" */),
  "component---src-pages-rules-js": () => import("./../../../src/pages/rules.js" /* webpackChunkName: "component---src-pages-rules-js" */),
  "component---src-pages-rules-sv-js": () => import("./../../../src/pages/rules.sv.js" /* webpackChunkName: "component---src-pages-rules-sv-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-success-sv-js": () => import("./../../../src/pages/success.sv.js" /* webpackChunkName: "component---src-pages-success-sv-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-user-js": () => import("./../../../src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */)
}

