import React, { useState, useRef } from "react"
import { navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown, faComment } from '@fortawesome/free-regular-svg-icons';


export default function VoteWrapper(props) {

	return (
		<div className="voteWrapper">
			<div className="voteUp" onClick={(event) => props.vote(event, 1)}>
				<FontAwesomeIcon icon={props.votedUp != undefined ? props.votedUp : faThumbsUp} />
			</div>
			<div className="votes">
				{props.score}
			</div>
			<div className={props.score == 0 ? 'voteDown disabled' : 'voteDown'} onClick={props.score > 0 ? (event) => props.vote(event, -1) : null}>
				<FontAwesomeIcon icon={props.votedDown != undefined ? props.votedDown : faThumbsDown} />
			</div>
		</div>				
	);
	
}

