import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import Drawer from "./drawer";
import AppConfig from '../appconfig';
import {
  setRooms,
  setConversations,
  setMobileDrawerOpen,
  setTotalUnread
} from "../state/actions";


class DrawerContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			loading: false
		};

		this.toggleMobileDrawerOpen = this.toggleMobileDrawerOpen.bind(this);
	}

	componentDidMount() {
		//this.getRoomsAndConversations();
	}

	toggleMobileDrawerOpen() {
		this.props.setMobileDrawerOpen(!this.props.mobileDrawerOpen);
	};

	render() {
		return (
			<Drawer mobileDrawerOpen={this.props.mobileDrawerOpen} toggleMobileDrawerOpen={this.toggleMobileDrawerOpen} 
				setMobileDrawerOpen={this.props.setMobileDrawerOpen} loading={this.state.loading || this.props.initialLoader} langUrl={this.props.langUrl} />
		);
	 }
}

function mapStateToProps(state) {
	const { loggedIn, rooms, conversations, mobileDrawerOpen, initialLoader } = state
	return { 
		loggedIn: loggedIn,
		rooms: rooms,
		conversations: conversations,
		mobileDrawerOpen: mobileDrawerOpen,
		initialLoader: initialLoader
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			setRooms: setRooms,
			setConversations: setConversations,
			setMobileDrawerOpen: setMobileDrawerOpen,
			setTotalUnread: setTotalUnread
		},
		dispatch
	);}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DrawerContainer);