import React from "react"
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AppConfig from '../appconfig';
import {
  setUnread,
  removeConversation,
  reduceTotalUnread
} from "../state/actions";


class DrawerRoomItem extends React.Component {

	componentDidMount() {
		// Save the unread amount to the global state
		let unreadStateName = "unreadroom"+this.props.name.toLowerCase();
		
		this.props.setUnread(unreadStateName, this.props.unread);
	}


 	render() {
		let unreadStateName = "unreadroom"+this.props.name.toLowerCase();

		let unreadState = this.props.globalState[unreadStateName];
		let unread = unreadState != undefined ? unreadState : 0;
 		return (
	 		<ListItem className="drawerListItem" button>
			  	<div className={unread == 0 ? 'unreadIcon visibilityHidden' : 'unreadIcon'}> <Badge variant="info">{unread}</Badge></div>
				<ListItemText primary={this.props.name} />
		  </ListItem>
		);
	 }
}

function mapStateToProps(state) {
	return { 
		globalState: state
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		setUnread: setUnread,
		removeConversation: removeConversation,
		reduceTotalUnread: reduceTotalUnread
	},
	dispatch
	);
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DrawerRoomItem);