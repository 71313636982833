import React, { useState, useRef } from "react"
import { navigate } from 'gatsby';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import AppConfig from '../appconfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faBan, faEnvelope, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import anonUser from '../../static/anon_user.png';
import { FormattedMessage } from 'react-intl';



export default function Tag(props) {
	
	let name = props.name;
	let color = 'red';
	if (name == 'm' || name == 'l' || name == 'kd') {
		color = 'blue';
	} else if (name == 'mp' || name == 'c') {
		color = 'green';
	} else if (name == 'sd') {
		color = 'yellow';
	}

	return (
		<span className={"tag " + color} onClick={props.tagSelected ? () => props.tagSelected(props.name, props.selected) : undefined} >
			{name}
			{props.selected && 
				<span className="remove"></span>
			}
		</span>
	);
	
}



