import React from "react";
import { navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVenus, faMars, faGenderless, faCrown, faHeart, faCaretUp, faAdjust, faCircle, faChessQueen } from '@fortawesome/free-solid-svg-icons';
import { faFrownOpen, faThumbsUp, faThumbsDown } from '@fortawesome/free-regular-svg-icons';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import AppConfig from '../appconfig';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import anonUser from '../../static/anon_user.png';
import SEO from "../components/seo";
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from'moment/min/moment-with-locales';


class UserContentInner extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			username: "",
			name: "",
			description: "",
			age: "",
			avatarUrl: "",
			gender: "",
			isModerator: false,
			isMember: false,
			pageLoading: true,
			showImageLightBox: false,
			imageLightBoxSrc: '',
			errorText: '',
			totalStat: 0,
			lastSeen: 0
		};

		this.handleImgClick = this.handleImgClick.bind(this);
	}

	componentDidMount() {
		if (this.props.username != undefined && this.props.username != '') {
			this.getProfileData();
		} else {
			console.log('No username provided');
		}
		
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		// Check if the user has been changed since last component update, do nothing if it's the same user.
		if (prevProps.username != this.props.username) {
			if (this.props.username != undefined && this.props.username != '') {
				this.getProfileData();
			} else {
				console.log('No username provided');
			}
		}		
	}


 	getProfileData() {
 		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		if (userId == undefined || userId == '' || 
			sessionId == undefined || sessionId == '' ||
			!this.props.loggedIn) {
			// Not logged in.
			return false;
		}

		let url = AppConfig.API_URL + 'getProfileData';

		url += ('/' + userId);
		url += ('/' + sessionId);
		url += ('/' + this.props.username);

	 	fetch(url, {
	 		credentials: 'include'
	 	})
		.then(res => res.json())
		.then(
			result => {
				if (result.success) {
					// Set the local state for the component
					this.setState({
						username: result.username,
						name: result.name,
						description: result.description,
						age: result.age,
						avatarUrl: result.avatarUrl,
						gender: result.gender,
						isModerator: result.isModerator,
						isMember: result.isMember,
						pageLoading: false,
						totalStat: result.totalStat,
						lastSeen: result.lastSeen
					});
				} else {
					let error = this.props.intl.formatMessage({ id: 'errorTryLater' });
					if (result.errorCode == 1) {
						error = this.props.intl.formatMessage({ id: 'userNotFound' });
					} else if (result.errorCode == 101) {
						window.location.reload();
					}

					this.setState({
						pageLoading: false,
						errorText: error
					});
				}
			},
			error => {
				this.setState({
					pageLoading: false,
					errorText: this.props.intl.formatMessage({ id: 'errorTryLater' })
				});
			}
		);

		return false; 		
 	}



	handleImgClick(e, data) {
		this.setState({
			imageLightBoxSrc: data,
			showImageLightBox: true
		});
	}

 	render() {
 		let lastSeen;
		if (this.state.lastSeen == 1) {
			lastSeen = (<div className="d-flex">
							<div>
								<span className="onlineIconWrapper">
									<span className="onlineIcon "></span>
								</span>
								<span className="online"> Online</span>
							</div>
						</div>);
		} else {
			let date = moment(this.state.lastSeen);
			var locale = window.navigator.userLanguage || window.navigator.language;
			date.locale(locale);

			let now = moment();
			let diff = now.diff(date, "months");
			if (diff > 6) {
				// More than 6 months since last login.
				lastSeen = (<div className="userLastSeen">Sågs senast <FormattedMessage id="longTimeAgo" /></div>);
			} else {
				lastSeen = (<div className="userLastSeen">Sågs senast {date.fromNow()}</div>);
			}
		}

 		let imgSrc = this.state.avatarUrl;
 		if (this.state.avatarUrl == undefined || this.state.avatarUrl == '') {
 			imgSrc = anonUser;
 		}

 		let name = this.state.name;
 		if (name == undefined || name == '') {
 			name = this.state.username;
 		}

 		let age = this.state.age;
 		if (age == undefined || age == '') {
 			age = '-';
 		}

 		let genderIcon = faGenderless;
 		let genderText = this.props.intl.formatMessage({ id: 'other' });
 		if (this.state.gender == 'woman') {
 			genderIcon = faVenus;
 			genderText = this.props.intl.formatMessage({ id: 'woman' });
 		} else if (this.state.gender == 'man') {
 			genderIcon = faMars;
 			genderText = this.props.intl.formatMessage({ id: 'man' });
 		}

 		let roleText = this.props.intl.formatMessage({ id: 'guest' });
 		if (this.state.isModerator) {
 			roleText = 'Moderator';
 		} else if (this.state.isMember) {
 			roleText = this.props.intl.formatMessage({ id: 'member' });
 		}

 		let totalIcon = faGenderless;
 		if (this.state.totalStat == -1) {
 			// Do nothing
 		} else if (this.state.totalStat >= 50000) {
 			totalIcon = faCrown;
 		} else if (this.state.totalStat >= 10000) {
 			totalIcon = faChessQueen;
 		} else if (this.state.totalStat >= 3000) {
 			totalIcon = faHeart;
 		} else if (this.state.totalStat >= 1000) {
 			totalIcon = faCircle;
 		} else if (this.state.totalStat >= 500) {
 			totalIcon = faAdjust;
 		} else if (this.state.totalStat >= 50) {
 			totalIcon = faCaretUp;
 		}

    	return (
		
			<div className="profilePageWrapper page">
				<SEO 	                
	                title={this.props.globalState.totalUnread == 0 ? 
	                	this.props.username : 
	                	this.props.globalState.totalUnread + " " + this.props.intl.formatMessage({ id: 'dM' })} 
	                description={this.props.intl.formatMessage({ id: 'userSeoDesc' })}
	                pagePath="/user"
	            />

				<div className="h-100 chatWrapper">

					<div className="row profileContent">

						<div className="col-xl-6 chat">
								
								<div className={this.state.pageLoading ? 'dcLoader medium preLoader' : 'hidden'} />

								{this.state.showImageLightBox &&
									<Lightbox
										mainSrc={this.state.imageLightBoxSrc}
										onCloseRequest={() => this.setState({ showImageLightBox: false })}
									/>
								}

								<div className={this.state.pageLoading ? 'card-body msgCardBody visibilityHidden' : 'card-body msgCardBody'}>
									
									<div className="msgWrapper">
										
										{this.state.errorText == '' && !this.state.pageLoading ? 
											<div>
							
											  <div className="main-content">
											  
											    <div className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center" />

											    <div className="container-fluid profileCardWrap">
											      <div className="row">
											        <div className="profileCardOuter mb-5">
											          <div className="card card-profile cardShadow">
											            <div className="row justify-content-center">
											              <div className="col-lg-3 order-lg-2">
											                <div className="card-profile-image">
											                  	<div className="profileImgContainer">
																	<img src={imgSrc} className="rounded-circle" />
																</div>
											                </div>
											              </div>
											            </div>
											            <div className="card-header text-center border-0 pt-8 pt-md-4 pb-0">
											              <div className="d-flex justify-content-between">

											                <div className={"btn btn-sm userRoleBtn float-right" + 
											                	(this.state.isVerified ? ' verified' : ' disabled')}>
											                		{roleText}
											                </div>
											              </div>
											            </div>
											            <div className="card-body pt-0 pt-md-4">
											            	<div className="userSubHeading">
												                <div className="">
												                  <div className="card-profile-gender d-flex justify-content-center mt-md-5">
												                      <span className="genderDescription"><FontAwesomeIcon icon={genderIcon} /> {genderText}</span>
												                  </div>
												                </div>

												                <div className="genderDescription">|</div>

												                {lastSeen}

											              	</div>
											          		<div className="row">
																<div className="col">
																	<div className="card-profile-stats d-flex justify-content-center">
																		
																		{this.state.totalStat != -1 ? 
																			<div>
																				<span className="heading"><FontAwesomeIcon icon={totalIcon} /> {this.state.totalStat}</span>
																				<span className="description">Total</span>
																			</div>
																		 : null
																		}
																		
																		<div>
																			
																		</div>
																	</div>
																</div>
															</div>

											              <div className="text-center">
											                <h3>
											                  {name}<span className="font-weight-light">, {age}</span>
											                </h3>
											                <div className="h6 font-weight-300 primaryColor">
											                  <i className="ni location_pin mr-2"></i>{this.state.username}
											                </div>
											                <hr className="my-4" />
											                <p>{this.state.description}</p>
											              </div>
											            </div>
											          </div>
											        </div>
											      </div>


												</div>
											  </div>
										</div>

										 : null
										}

										{this.state.errorText != '' &&  !this.state.pageLoading ? 
											<div className="errorText">{this.state.errorText} <br /> <FontAwesomeIcon icon={faFrownOpen} /></div>
										 : null
										}

										
									</div>
									
								</div>
						</div>

					</div>
				</div>

			</div>
		);
	}
}

function mapStateToProps(state) {
	const { loggedIn, messages } = state
	return { 
		loggedIn: loggedIn,
		globalState: state
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({

	},
	dispatch
	);
}

export default injectIntl(connect(
	mapStateToProps,
	mapDispatchToProps
)(UserContentInner));
