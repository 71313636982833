import { createStore as reduxCreateStore } from "redux"


const initialState = {
	//roomdata[roomName] state is created and fetched dynamically and store the data and messages of each fetched room
	//conversationdata[recipient] state is created and fetched dynamically and store the data and messages of each fetched conversation
	//unreadconversation[recipient] state is created and fetched dynamically and store the unread count for a convo
	//unreadroom[roomName] state is created and fetched dynamically and store the unread count for a room
	loggedIn: false,
	avatarUrl: '',
	totalUnread: 0,
	rooms: [],
	conversations: [],
	messages: "",
	directMessages: [],
	showSignInModal: false,
	allowDm: true,
	blockedUsernames: [],
	initialLoader: true,
	mobileDrawerOpen: false,
	selectedLang: '',
	dmSounds: false,
	plusMember: false,
	isGuest: false,
	enteredName: '',
	unavailable: false,
	clientFp: ''
}


const reducer = (state, action) => {
	let unreadCount;
	let roomState;
	let roomData;

	switch (action.type) {
		case "SET_LOGGED_IN":
			return {
				...state, loggedIn: action.payload
			}

		case "SET_AVATAR_URL":
			return {
				...state, avatarUrl: action.payload
			}

		case "SET_INITIAL_LOADER":
			return {
				...state, initialLoader: action.payload
			}

		case "SET_TOTAL_UNREAD":
			return {
				...state, totalUnread: action.payload
			}

		case "SET_MOBILE_DRAWER_OPEN":
			return {
				...state, mobileDrawerOpen: action.payload
			}

		case "SET_ROOMS":
			return {
				...state, rooms: action.payload
			}

		case "ADD_ROOM":
			// To avoid adding the room twice. Sometimes done for guest users who join when in a room page. 
			for (var i = 0; i < state.rooms; i++) {
				if (state.rooms[i].roomId == action.payload.roomId) {
					return state;
				}
			}

			return {
				//...state, rooms: [...state.rooms, ...action.payload]
				...state, rooms: state.rooms.concat(action.payload)
			}

		case "REMOVE_ROOM":
			return {
				...state, rooms: state.rooms.filter(room => room.roomName != action.payload)
			}

		case "SET_CONVERSATIONS":
			return {
				...state, conversations: action.payload
			}

		case "ADD_CONVERSATION":
			return {
				//...state, rooms: [...state.rooms, ...action.payload]
				...state, conversations: [action.payload].concat(state.conversations)
			}

		case "UPDATE_CONVERSATION":
			let conversations = state.conversations.slice();
			if (conversations) {
				conversations[action.indexToUpdate] = action.newConversation;
			}

			return {
				...state, conversations: conversations
			}

		case "REMOVE_CONVERSATION":
			// Remove the convo.
			return {
				...state, conversations: state.conversations.filter(conversation => conversation.recipientUsername != action.payload)
			}

		case "ADD_ROOM_DATA":
			return {
				...state, [action.roomStateName]: action.data
			}

		case "ADD_OR_UPDATE_ROOM_DATA":
			roomData = { ...state[action.roomStateName] };

			if (Object.keys(roomData).length === 0) {
				// No room data yet. Use the provided data.
				return {
					...state, [action.roomStateName]: action.roomData
				}
			}

			// Set provided messages in the existing data.
			roomData.messages = roomData.messages.concat(action.roomData.messages);

			return {
				...state, [action.roomStateName]: roomData
			}

		case "UPDATE_ROOM_DATA":
			roomData = state[action.roomStateName];
			roomData[action.valueName] = action.value;

			return {
				...state, [action.roomStateName]: roomData
			}

		case "UPDATE_CONVERSATION_DATA":
			let conversationData = state[action.conversationStateName];
			if (!conversationData) {
				return state;
			}
			conversationData[action.valueName] = action.value;

			return {
				...state, [action.conversationStateName]: conversationData
			}

		case "ADD_CONVERSATION_DATA":
			return {
				...state, [action.conversationStateName]: action.data
			}

		case "UPDATE_CONVERSATION_DATA":
			conversationData = { ...state[action.conversationStateName] };
			if (Object.keys(conversationData).length === 0) {
				return state;
			}
			conversationData[action.valueName] = action.value;

			return {
				...state, [action.conversationStateName]: conversationData
			}

		case "ADD_OR_UPDATE_CONVERSATION_DATA":
			conversationData = { ...state[action.conversationStateName] };

			if (Object.keys(conversationData).length === 0) {
				// No conversation data yet. Use the provided data.
				return {
					...state, [action.conversationStateName]: action.conversationData
				}
			}

			// Set provided messages in the existing data.
			conversationData.messages = conversationData.messages.concat(action.conversationData.messages);

			return {
				...state, [action.conversationStateName]: conversationData
			}

		case "UPDATE_MESSAGE_IN_CONVERSATION_DATA":
			conversationData = { ...state[action.conversationStateName] };

			if (Object.keys(conversationData).length === 0) {
				return state;
			}

			if (!Array.isArray(conversationData.messages)) {
				return state;
			}

			let totalMessages = conversationData.messages;

			// Try to find the message in the messages
			const messageIndex = totalMessages.findIndex(message => message.id === action.messageId);

			//console.log('messageIndex ' + messageIndex)

			if (messageIndex === -1) {
				if (action.valueName === 'expiryTime') {
					// Add the message if it doesnt exist for expiryTime updates, since it can arrive before the message is added.
					// Will be merged later with the message when the message is added.
					let message = {
						id: action.messageId,
						key: action.messageId,
						expiryTime: action.value,
						type: 400
					}

					totalMessages.push(message);
				} else {
					return state;
				}
			} else {
				// Message found. Update the value in the message and add it to the messages 
				const messageToUpdate = { ...totalMessages[messageIndex] };
				messageToUpdate[action.valueName] = action.value;
				totalMessages[messageIndex] = messageToUpdate;
			}

			conversationData.messages = totalMessages;

			return {
				...state, [action.conversationStateName]: conversationData
			}

		case "REFRESH_STATE_DATA":
			let data = state[action.stateName];

			return {
				...state, [action.stateName]: data
			}

		case "REMOVE_ROOM_DATA":
			return {
				...state, [action.roomStateName]: null
			}

		case "SET_UNREAD":
			// Set the unread count.
			return {
				...state, [action.unreadStateName]: action.data
				//,totalUnread: (state.totalUnread + action.data)
			}

		case "ADD_UNREAD":
			// Increase the unread count if it already exists, otherwise just save the count. 
			if (state[action.unreadStateName] == undefined) {
				unreadCount = action.data
			} else {
				unreadCount = state[action.unreadStateName] + action.data
			}

			return {
				...state, [action.unreadStateName]: unreadCount
			}

		case "ADD_TO_TOTAL_UNREAD":
			// Add the count to the total unread.
			return {
				...state, totalUnread: (state.totalUnread + action.data)
			}

		case "REDUCE_TOTAL_UNREAD":
			// Reduce the count of the total unread.
			unreadCount = state[action.unreadStateName];
			if (unreadCount == undefined) {
				unreadCount = 0;
			}

			let newUnreadCount = state.totalUnread - unreadCount;

			return {
				//...state, totalUnread: (state.totalUnread - unreadCount)
				...state, totalUnread: (newUnreadCount > 0 ? newUnreadCount : 0)
			}

		case "RESET_UNREAD":
			// Reset the unread count for the convo.
			return {
				...state, [action.unreadStateName]: 0
			}

		case "SET_ALLOW_DM":
			return {
				...state, allowDm: action.payload
			}

		case "SET_DM_SOUNDS":
			return {
				...state, dmSounds: action.payload
			}

		case "SET_BLOCKED_USERNAMES":
			return {
				...state, blockedUsernames: action.payload
			}

		case "ADD_BLOCKED_USERNAME":
			return {
				...state, blockedUsernames: state.blockedUsernames.concat(action.payload)
			}

		case "REMOVE_BLOCKED_USERNAME":
			return {
				...state, blockedUsernames: state.blockedUsernames.filter(username => username != action.payload)
			}

		case "SET_SHOW_SIGN_IN_MODAL":
			return {
				//...state, [action.payload]: true
				...state, showSignInModal: action.payload
			}
		case "REMOVE_USER_MSGS":
			roomState = { ...state[action.stateName] };
			if (roomState.messages == undefined) {
				return state;
			}
			roomState.messages = roomState.messages.filter(message => message.username != action.payload);

			return {
				...state, [action.stateName]: roomState
			}
		case "REMOVE_MESSAGE":
			roomState = { ...state[action.stateName] };

			if (roomState.messages == undefined) {
				return state;
			}

			roomState.messages = roomState.messages.filter(message => message.id != action.payload);

			return {
				...state, [action.stateName]: roomState
			}
		case "SET_SELECTED_LANG":
			localStorage.setItem("selectedLang", action.payload);
			return {
				...state, selectedLang: action.payload
			}

		case "SET_PLUS_MEMBER":
			return {
				...state, plusMember: action.payload
			}

		case "SET_IS_GUEST":
			return {
				...state, isGuest: action.payload
			}

		case "SET_ENTERED_NAME":
			return {
				...state, enteredName: action.payload
			}

		case "SET_UNAVAILABLE":
			return {
				...state, unavailable: action.payload
			}

		case "SET_CLIENT_FP":
			return {
				...state, clientFp: action.payload
			}
		case "RESET":
			return initialState;


		default:
			return state;
	}

}

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore