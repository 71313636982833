import React from "react"
import { navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faInfoCircle, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'
import { faFrownOpen, faComment } from '@fortawesome/free-regular-svg-icons';
import UserListButton from "./userListButton"
import UserList from "./userList"
import Comment from "./comment"
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ProgressBar from 'react-bootstrap/ProgressBar';
import AppConfig from '../appconfig';
import handleViewport from 'react-in-viewport';
import ChatFooter from './chatFooter';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import { v4 as uuidv4 } from 'uuid';
import InfoModal from "./infoModal";
//import InterstitialModal from "./interstitialModal";
import anonUser from '../../static/anon_user.png';
import SEO from "../components/seo";
import {
	reset,
	addRoomData,
	updateRoomData,
	removeRoomData,
	addRoom,
	removeRoom,
	resetUnread,
	setAllowDm,
	addBlockedUsername,
	removeBlockedUsername,
	refreshStateData
} from "../state/actions";
import { FormattedMessage, injectIntl } from 'react-intl';
import AdBannerAdult from "../components/adBannerAdult";
import Tag from "./tag";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import moment from 'moment';
import 'moment/locale/sv';
import VoteWrapper from "./voteWrapper";



class PostContentInner extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			postId: 0,
			title: this.props.postTitle,
			text: "",
			created: "",
			topic: "",
			type: "",
			tags: [],
			imageUrl: "",
			scrollPosition: "",
			uploading: false,
			uploadingProgress: 0,
			loading: true,
			showImageLightBox: false,
			imageLightBoxSrc: '',
			errorText: '',
			showInfoModal: false,
			showInterstitialModal: false,
			interstitialModalAd: '',
			infoModalText: '',
			showBannerAd: false,
			showExtendedSettings: false,
			votedUp: undefined,
			votedDown: undefined,
			score: 0,
			amountOfComments: 0,
			comments: [],
			respondUsername: undefined,
			respondCommentId: 0,
			fetchedIds: [],
			link: ""
		};


		this.createComment = this.createComment.bind(this);
		this.onUploadChange = this.onUploadChange.bind(this);
		this.onVoiceRecord = this.onVoiceRecord.bind(this);
		this.imageLoaded = this.imageLoaded.bind(this);
		this.handleImgClick = this.handleImgClick.bind(this);
		this.setLastRead = this.setLastRead.bind(this);
		this.handleLeaveRoomClick = this.handleLeaveRoomClick.bind(this);
		this.handleRoomInfoClick = this.handleRoomInfoClick.bind(this);
		this.closeInfoModal = this.closeInfoModal.bind(this);
		this.closeInterstitialModal = this.closeInterstitialModal.bind(this);
		this.setShowBannerAd = this.setShowBannerAd.bind(this);
		this.vote = this.vote.bind(this);
		this.openInfoModal = this.openInfoModal.bind(this);
		this.resetVote = this.resetVote.bind(this);
		this.setRespondData = this.setRespondData.bind(this);
		this.setReplies = this.setReplies.bind(this);
		this.getComments = this.getComments.bind(this);
		this.handleImgClick = this.handleImgClick.bind(this);
	}

	componentDidMount() {
		if (this.props.postTitle != undefined && this.props.postTitle != '') {
			this.getPostData();
		} else {
			//No title provided
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// Check if the post has been changed since last component update, do nothing if it's the same post.
		if (prevProps.postTitle != this.props.postTitle) {
			// Get the room data from the global state if it exists.
			if (this.props.postTitle != undefined && this.props.postTitle != '') {

				// Set the state to show loading and fetch the data.
				this.setState({
					showUserList: false,
					loading: true,
					roomName: "",
					roomDesc: ""
				});
				this.getPostData();
			} else {
				//No post name provided
			}
		}
	}


	getPostData() {
		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'getPostData';

		url += ('/' + userId);
		url += ('/' + sessionId);
		url += ('/' + this.props.postTitle);

		fetch(url, {
			credentials: 'include'
		})
			.then(res => res.json())
			.then(
				result => {
					if (result.success) {
						let comments = result.comments == undefined ? [] : result.comments;
						this.setState({
							postId: result.post.id,
							title: result.post.title,
							text: result.post.text,
							created: result.post.created,
							topic: result.post.topic,
							type: result.post.type,
							tags: result.post.tags,
							imageUrl: result.post.imageUrl,
							score: result.post.score,
							amountOfComments: result.post.comments,
							comments: comments,
							fetchedIds: this.state.fetchedIds.concat(result.fetchedIds),
							loading: false,
							showMoreLoader: comments.length == 10 ? true : false,
							errorText: '',
							showExtendedSettings: result.isOwner,
							votedUp: result.userVote == 1 ? faThumbsUp : undefined,
							votedDown: result.userVote == -1 ? faThumbsDown : undefined,
							link: result.post.link
						}, () => {
							this.getComments();
						}
						);

					} else {
						let error = this.props.intl.formatMessage({ id: 'errorTryLater' });

						if (result.errorCode == 1) {
							error = this.props.intl.formatMessage({ id: 'roomNotFound' });
						} else if (result.errorCode == 2) {
							// Could not join room
						} else if (result.errorCode == 3) {
							// Not a woman tried to join girls chat.
							error = this.props.intl.formatMessage({ id: 'onlyGirlsAllowed' });
						} else if (result.errorCode == 4) {
							// Been thrown out before
							error = this.props.intl.formatMessage({ id: 'beenRemoved' });
						} else if (result.errorCode == 101) {
							window.location.reload();
						}

						this.setState({
							loading: false,
							errorText: error
						});
					}
				},
				error => {
					console.log("Error");
					this.setState({
						loading: false,
						errorText: this.props.intl.formatMessage({ id: 'errorTryLater' })
					});
				}
			);

		return false;
	}

	getComments() {
		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let messagesWrapper = document.getElementsByClassName('msgWrapper')[0];

		this.setState({
			scrollPosition: messagesWrapper.scrollHeight
		});

		let url = AppConfig.API_URL + 'getComments';

		fetch(url, {
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				userId: userId,
				sessionId: sessionId,
				parentId: this.state.postId,
				fetchedIds: this.state.fetchedIds,
			})
		})
			.then(res => res.json())
			.then(
				result => {
					if (result.success) {
						this.setState({
							comments: this.state.comments.concat(result.comments),
							isLoading: false,
							showMoreLoader: result.comments.length == 10 ? true : false,
							fetchedIds: this.state.fetchedIds.concat(result.fetchedIds)
						});
					} else {
						let errorMsg = this.props.intl.formatMessage({ id: 'errorTryLater' });
						if (result.errorCode == 101) {
							window.location.reload();
						} else {
							this.setState({
								showInfoModal: true,
								infoModalText: errorMsg
							});
						}
					}
				},
				error => {
					console.log("Error");
				}
			);

		return false;
	}

	createComment(newComment) {
		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');
		let username = localStorage.getItem('username');

		if (userId == undefined || userId == '' ||
			sessionId == undefined || sessionId == '' ||
			!this.props.loggedIn) {
			this.setState({
				showInfoModal: true,
				infoModalText: "Logga in för att diskutera. Det tar bara några sekunder!"
			});
			return false;
		}

		// Temporary commentId
		let tempCommentId = uuidv4();
		let comment;

		let type = this.state.respondUsername == undefined ? 1 : 2;

		// Get the previous messages from the state.
		let comments = this.state.comments;

		let possibleLink = false;
		if (newComment.includes('http')) {
			// Possible link, dont add to message state yet, wait for backend checks, then add to messages when finished.
			possibleLink = true;
		} else {
			// Create a comment from the input	
			comment = this.createCommentObject(tempCommentId, newComment, userId, username, type, 1);
		}

		let url = AppConfig.API_URL + 'createComment';

		fetch(url, {
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				userId: userId,
				sessionId: sessionId,
				postId: this.state.postId,
				text: newComment,
				type: type,
				commentId: this.state.respondCommentId,
				mediaType: 1,
				possibleLink: possibleLink
			})
		})
			.then(res => res.json())
			.then(
				result => {
					if (result.success) {
						if (possibleLink) {
							// Was a possbile link and hasnt been added yet.
							// Create a comment from the input	createCommentObject
							comment = this.createCommentObject(tempCommentId, newComment, userId, username, type, 1);
						}

						// Message sent successfully, update the message to not pending and it's id.

						if (type == 2) {
							// Was a reply, add the comment to the parent's comment list
							for (var i = 0; i < comments.length; i++) {
								let tempComment = comments[i];
								if (tempComment.id == this.state.respondCommentId) {
									let replies = tempComment.replies;
									if (replies == undefined) {
										//replies = [comment];
									} else {
										for (var j = 0; j < replies.length; j++) {
											let tempReply = replies[j];
											if (tempReply.id == tempCommentId) {
												tempReply.pending = false;
												tempReply.id = result.id;
											}
										}

									}
								}
							}
						} else {
							for (var i = 0; i < comments.length; i++) {
								let tempComment = comments[i];
								if (tempComment.id == tempCommentId) {
									tempComment.pending = false;
									tempComment.id = result.id;
								}
							}
						}

						this.setState({
							comments: comments,
							fetchedIds: this.state.fetchedIds.concat(result.id)
						});

						if (type == 1) {
							this.scrollToBottom();
						}
					} else {
						let errorMsg = this.props.intl.formatMessage({ id: 'errorTryLater' })

						if (result.errorCode == 3) {
							// Set the state to show silenced
							this.setState({
								showInfoModal: true,
								infoModalText: "Du är tystad och kan inte prata just nu"
							});

							// Remove the temporary sent comment from the state.
							let updatedComments = comments.filter(comment => comment.id != tempCommentId);
							this.setState({
								comments: updatedComments
							});
						} else if (result.errorCode == 4) {
							// Set the state to show too long message
							this.setState({
								showInfoModal: true,
								infoModalText: this.props.intl.formatMessage({ id: 'msgCantBeLonger' })
							});

							// Remove the temporary sent comment from the state.
							let updatedComments = comments.filter(comment => comment.id != tempCommentId);
							this.setState({
								comments: updatedComments
							});
						} else if (result.errorCode == 101) {
							window.location.reload();
						} else if (result.errorCode == 108) {
							// Has been thrown out
							this.setState({
								showInfoModal: true,
								infoModalText: this.props.intl.formatMessage({ id: 'notAllowed' })
							});
						} else {
							this.setState({
								showInfoModal: true,
								infoModalText: errorMsg
							});
						}
					}
				},
				error => {
					console.log("Error");
				}
			);

		return false;
	}

	createCommentObject(tempCommentId, newComment, userId, username, type, mediaType) {
		let comments = this.state.comments;

		let comment = {
			id: tempCommentId,
			key: tempCommentId,
			text: newComment,
			created: Date.now(),
			userId: userId,
			username: username,
			type: type,
			commentId: this.state.respondCommentId,
			avatarUrl: this.props.globalState.avatarUrl,
			pending: true,
			score: 1,
			userVote: 1,
			mediaType: mediaType
		}

		// Add the comment to the parent's comment list if it is a reply
		if (type == 2) {
			let respondCommentId = this.state.respondCommentId;

			comments.forEach(function (value, index, array) {
				if (value.id == respondCommentId) {
					let replies = value.replies;
					if (replies == undefined) {
						replies = [comment];
						value.replies = replies;
					} else {
						replies.push(comment);
					}
				}
			});
		} else {
			comments.push(comment);
		}

		this.setState({
			comments: comments
		});

		return comment;
	}

	setLastRead() {
		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'setLastRead';

		fetch(url, {
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				userId: userId,
				sessionId: sessionId,
				roomId: this.state.roomId,
				type: 1
			})
		})
			.then(res => res.json())
			.then(
				result => {
					if (result.success) {

					} else {
						if (result.errorCode == 101) {
							window.location.reload();
						}
					}
				},
				error => {
					console.log("Internal error");
				}
			);

		return false;
	}

	handleImgClick(e, data) {
		this.setState({
			imageLightBoxSrc: data,
			showImageLightBox: true
		});
	}

	scrollToBottom() {
		let messagesWrapper = document.getElementsByClassName('msgWrapper')[0];
		if (messagesWrapper != undefined) {
			messagesWrapper.scrollTop = messagesWrapper.scrollHeight;
		}
	}

	scrollIfAtBottom() {
		// Only scroll if user is at bottom of the page. Add 200px to enable scrolling if the user is right above the bottom, like when a new message has been added.
		let messagesWrapper = document.getElementsByClassName('msgWrapper')[0];
		let shouldScroll = messagesWrapper.scrollTop + messagesWrapper.clientHeight + 200 >= messagesWrapper.scrollHeight;
		if (shouldScroll) {
			this.scrollToBottom();
		}
	}

	scrollToLastPosition() {
		let messagesWrapper = document.getElementsByClassName('msgWrapper')[0];
		messagesWrapper.scrollTop = messagesWrapper.scrollHeight - this.state.scrollPosition;
	}

	onLoadVisible() {
		if (!this.state.loading) {
			this.getComments();
		}
	}

	incUploadingProgress(value) {
		let uploadingProgress = this.state.uploadingProgress;

		this.setState({
			uploadingProgress: this.state.uploadingProgress + 10
		});
	}

	imageLoaded(value) {
		/*
		if (this.state.imagesLeftToLoad = 1) {
			// This is the last image to be loaded. Scroll to last position now that all images are finished.
			this.scrollToLastPosition();
		}
		*/

		this.setState({
			imagesLeftToLoad: this.state.imagesLeftToLoad - 1
		});
	}

	onUploadChange(e) {
		this.setState({
			uploading: true,
			uploadingProgress: 0
		});
		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');
		let username = localStorage.getItem('username');

		let type = this.state.respondUsername == undefined ? 1 : 2;

		const files = Array.from(e.target.files);
		//this.setState({ uploading: true })

		const formData = new FormData();

		files.forEach((file, i) => {
			formData.append("file", file)
		})

		formData.append("postId", this.state.postId);
		formData.append("userId", userId);
		formData.append("sessionId", sessionId);
		formData.append("type", type);
		formData.append("commentId", this.state.respondCommentId);
		formData.append("avatarUrl", this.props.globalState.avatarUrl == undefined || this.props.globalState.avatarUrl == '' ? '' : this.props.globalState.avatarUrl);

		let url = AppConfig.API_URL + 'uploadFile';

		function timer() {
			this.incUploadingProgress(30);
			if (this.state.uploadingProgress == 0 || this.state.uploadingProgress >= 90) {
				clearInterval(this.intervalId);
			}
		}

		this.intervalId = setInterval(timer.bind(this), 600);

		fetch(url, {
			method: 'post',
			credentials: 'include',
			body: formData
		})
			.then(res => res.json())
			.then(
				result => {
					if (result.success && result.comment != undefined) {
						this.setState({
							uploadingProgress: 100,
							uploading: false
						});

						this.createCommentObject(result.comment.id, result.comment.text, userId, username, type, 2);

						this.scrollToBottom();
					} else {
						let errorText = this.props.intl.formatMessage({ id: 'fileNotSent' });
						if (result.errorCode == 1) {
							errorText = this.props.intl.formatMessage({ id: 'fileNotAllowed' });
						} else if (result.errorCode == 2) {
							errorText = this.props.intl.formatMessage({ id: 'fileTooBig' });
						} else if (result.errorCode == 3) {
							errorText = this.props.intl.formatMessage({ id: 'silencedCantTalk' });
						} else if (result.errorCode == 101) {
							window.location.reload();
						} else if (result.errorCode == 108) {
							// Has been thrown out
							errorText = this.props.intl.formatMessage({ id: 'notAllowed' });
						}

						this.setState({
							showInfoModal: true,
							infoModalText: errorText,
							uploading: false,
							uploadingProgress: 0
						});
					}
				},
				error => {
					console.log("Error");
					this.setState({
						uploading: false,
						uploadingProgress: 0
					});
				}
			);
	}

	onVoiceRecord(file) {
		var confirmRemoval = window.confirm(this.props.intl.formatMessage({ id: 'sendConfirmation' }));
		if (!confirmRemoval) {
			return;
		}

		this.setState({
			uploading: true,
			uploadingProgress: 0
		});
		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		const formData = new FormData();

		formData.append("file", file);

		formData.append("roomId", this.state.roomId);
		formData.append("userId", userId);
		formData.append("sessionId", sessionId);
		formData.append("roomName", this.state.roomName);
		formData.append("avatarUrl", this.props.globalState.avatarUrl == undefined || this.props.globalState.avatarUrl == '' ? '' : this.props.globalState.avatarUrl);

		let url = AppConfig.API_URL + 'uploadFile';

		function timer() {
			this.incUploadingProgress(30);
			if (this.state.uploadingProgress == 0 || this.state.uploadingProgress >= 90) {
				clearInterval(this.intervalId);
			}
		}

		this.intervalId = setInterval(timer.bind(this), 600);

		fetch(url, {
			method: 'post',
			credentials: 'include',
			body: formData
		})
			.then(res => res.json())
			.then(
				result => {
					if (result.success && result.message != undefined) {
						this.setState({
							uploadingProgress: 100,
							uploading: false
						});

						// Get the saved room data from the state.
						let roomStateName = "roomdata" + this.state.roomName.toLowerCase();
						let roomState = this.props.globalState[roomStateName];

						// Get the previous messages from the state.
						let totalMessages = [];
						if (roomState != undefined) {
							totalMessages = roomState.messages;
						}

						// Create a message from the input and add to messages in state.	
						let message = {
							id: result.message.id,
							key: result.message.id,
							contents: result.message.contents,
							created: result.message.created,
							userId: userId,
							username: result.message.username,
							type: result.message.type,
							avatarUrl: this.props.globalState.avatarUrl
						}
						totalMessages.push(message);

						// Save the messages to the global state
						this.props.updateRoomData(roomStateName, "messages", totalMessages);

						this.scrollToBottom();
					} else {
						let errorText = this.props.intl.formatMessage({ id: 'fileNotSent' });
						if (result.errorCode == 1) {
							errorText = this.props.intl.formatMessage({ id: 'fileNotAllowed' });
						} else if (result.errorCode == 2) {
							errorText = this.props.intl.formatMessage({ id: 'fileTooBig' });
						} else if (result.errorCode == 3) {
							errorText = this.props.intl.formatMessage({ id: 'silencedCantTalk' });
						} else if (result.errorCode == 101) {
							window.location.reload();
						} else if (result.errorCode == 108) {
							// Has been thrown out
							errorText = this.props.intl.formatMessage({ id: 'notAllowed' });
						}

						this.setState({
							showInfoModal: true,
							infoModalText: errorText,
							uploading: false,
							uploadingProgress: 0
						});
					}
				},
				error => {
					console.log("Error");
					this.setState({
						uploading: false,
						uploadingProgress: 0
					});
				}
			);
	}

	handleImgClick(e, data) {
		this.setState({
			imageLightBoxSrc: data,
			showImageLightBox: true
		});
	}

	closeInfoModal(e) {
		this.setState({
			showInfoModal: false
		});
	}

	openInfoModal(text) {
		this.setState({
			showInfoModal: true,
			infoModalText: text
		});
	}

	closeInterstitialModal(e) {
		this.setState({
			showInterstitialModal: false
		});
	}


	setShowBannerAd(value) {
		this.setState({
			showBannerAd: value
		});
	}

	handleLeaveRoomClick() {
		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'leaveRoom';

		fetch(url, {
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				userId: userId,
				sessionId: sessionId,
				roomId: this.state.roomId,
				roomName: this.state.roomName,
				avatarUrl: this.props.globalState.avatarUrl
			})
		})
			.then(res => res.json())
			.then(
				result => {
					if (result.success) {
						// Remove from drawer.
						this.props.removeRoom(this.state.roomId);
						let roomStateName = "roomdata" + this.props.roomName.toLowerCase();
						this.props.removeRoomData(roomStateName);

						navigate(this.props.langUrl + '/');
					} else {
						if (result.errorCode == 1) {
							// Owner of room
							this.setState({
								showInfoModal: true,
								infoModalText: this.props.intl.formatMessage({ id: 'createdNotRemove' })
							});
						} else if (result.errorCode == 101) {
							window.location.reload();
						}
					}
				},
				error => {
					console.log("Internal error");
				}
			);

		return false;
	}

	vote(event, voteType) {
		event.preventDefault();
		event.stopPropagation();

		let scoreUpdate = 1;
		if (voteType == -1) {
			// The user voted down.
			if (this.state.votedDown != undefined) {
				// Already voted down. Do nothing.
				return false;
			}

			if (this.state.votedUp != undefined) {
				// Already voted up. Reduce the score by 2.
				scoreUpdate = 2;
			}

			this.setState({
				votedUp: undefined,
				votedDown: faThumbsDown,
				score: this.state.score - scoreUpdate
			});
		} else {
			// This user voted up.
			if (this.state.votedUp != undefined) {
				// Already voted up. Do nothing.
				return false;
			}

			if (this.state.votedDown != undefined) {
				// Already voted down. Increase the score by 2.
				scoreUpdate = 2;
			}
			this.setState({
				votedUp: faThumbsUp,
				votedDown: undefined,
				score: this.state.score + scoreUpdate
			});
		}

		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'castPostVote';

		fetch(url, {
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				userId: userId,
				sessionId: sessionId,
				postId: this.state.postId,
				scoreVal: voteType
			})
		})
			.then(res => res.json())
			.then(
				result => {
					if (result.success) {
						// Do nothing
					} else {
						if (result.errorCode == 6) {
							alert('Det är inte tillåtet att rösta ned under 0');
						} else if (result.errorCode == 101) {
							window.location.reload();
						}

						this.resetVote(voteType);
					}
				},
				error => {
					console.log("Internal error");
				}
			);

		return false;
	}

	resetVote(voteType) {
		if (voteType == -1) {
			// The user voted down.

			this.setState({
				votedUp: undefined,
				votedDown: undefined,
				score: this.state.score + 1
			});
		} else {
			// This user voted up.

			this.setState({
				votedUp: undefined,
				votedDown: undefined,
				score: this.state.score - 1
			});
		}
	}

	handleRoomInfoClick() {
		// Show the modal f there is a room description, otherwise fetch it and then show it.
		if (this.state.roomDesc == undefined || this.state.roomDesc == '') {
			this.getLimitedRoomData();
		} else {
			this.setState({
				showInfoModal: true,
				infoModalText: this.state.roomDesc
			});
		}
	}

	setRespondData(username, commentId) {
		this.setState({
			respondUsername: username,
			respondCommentId: commentId
		});
	}

	setReplies(commentId, replies) {
		let comments = this.state.comments;
		for (var i = 0; i < comments.length; i++) {
			let tempComment = comments[i];
			if (tempComment.id == commentId) {
				let commentReplies = tempComment.replies;
				if (commentReplies == undefined) {
					//commentReplies = [comment];
				} else {
					tempComment.replies = commentReplies.concat(replies);
				}
			}
		}

		this.setState({
			comments: comments
		});
	}

	render() {
		let comments = [];

		let commentsState = this.state.comments;

		if (commentsState != undefined && commentsState.length > 0) {
			for (let i = 0; i < commentsState.length; i++) {
				let comment = commentsState[i];

				let imgSrc = comment.avatarUrl;
				if (comment.avatarUrl == undefined || comment.avatarUrl == '') {
					imgSrc = anonUser;
				}

				comments.push(
					<Comment key={comment.id + "cmt"}
						id={comment.id}
						respondId={comment.id}
						text={comment.text}
						time={comment.created}
						userId={comment.userId}
						username={comment.username}
						type={comment.type}
						mediaType={comment.mediaType}
						addBlockedUsername={this.props.addBlockedUsername}
						removeBlockedUsername={this.props.removeBlockedUsername}
						avatar={imgSrc}
						score={comment.score}
						postId={comment.postId}
						pending={comment.pending}
						votedDown={comment.userVote == -1 ? faThumbsDown : undefined} votedUp={comment.userVote == 1 ? faThumbsUp : undefined}
						setRespondData={this.setRespondData}
						replies={comment.replies}
						setReplies={this.setReplies}
						handleImgClick={this.handleImgClick}
					/>
				);
			}
		}

		let topic = "Övrigt";

		switch (this.state.topic) {
			case 1:
				topic = "Sjukvård"
				break;
			case 2:
				topic = "Lag & ordning"
				break;
			case 3:
				topic = "Migration"
				break;
			case 4:
				topic = "Utbildning"
				break;
			case 5:
				topic = "Äldreomsorg"
				break;
			case 6:
				topic = "Klimat"
				break;
			case 7:
				topic = "Jobb"
				break;
			case 8:
				topic = "Ekonomi"
				break;
			case 9:
				topic = "Jämlikhet"
				break;
			case 10:
				topic = "Sport"
				break;
			case 11:
				topic = "Nöje"
				break;
		}

		let arr = this.state.tags;
		let tags = [];

		if (arr != undefined && arr.length > 0) {
			for (let i = 0; i < arr.length; i++) {
				tags.push(
					<Tag key={arr[i]} name={arr[i]} />
				);
			}
		}

		let date = moment(this.state.created);

		let progressBar = null;
		if (this.state.uploading) {
			progressBar = <ProgressBar now={this.state.uploadingProgress} />
		}

		return (
			<div className="chatPageWrapper page">

				<SEO
					title={this.props.globalState.totalUnread == 0 ?
						this.state.title :
						this.props.globalState.totalUnread + " " + this.props.intl.formatMessage({ id: 'dM' })}
					description={this.props.intl.formatMessage({ id: 'roomSeoDesc' })}
					pagePath="/post"
				/>

				<div className="h-100 chatWrapper">
					<div className="chatHeader justify-content-center">

						{this.state.errorText != '' ?
							<div className="errorText">{this.state.errorText} <br /> <FontAwesomeIcon icon={faFrownOpen} /></div>
							:
							null
						}

					</div>

					<div className="row chatContent">

						<div className="col-xl-6 chat">

							<div className={this.state.loading || this.state.imagesLeftToLoad > 0 ? 'dcLoader medium' : 'hidden'} />

							{this.state.showImageLightBox &&
								<Lightbox
									mainSrc={this.state.imageLightBoxSrc}
									onCloseRequest={() => this.setState({ showImageLightBox: false })}
								/>
							}

							{progressBar}

							<div className={this.state.loading || this.state.imagesLeftToLoad > 0 ? 'card-body msgCardBody visibilityHidden' : 'card-body msgCardBody'}>

								<div className="msgWrapper">
									<div className="postPage" >
										<div className="" data-userid="testId" am-data='' seeking-data=''>

											<div className="postContent">

												<VoteWrapper vote={this.vote} votedUp={this.state.votedUp} votedDown={this.state.votedDown} score={this.state.score} />

												<div className="postText">
													<div className="postTextTitle">{this.state.title}</div>

													{this.state.type == 2 ?
														<div className="postTextContent">{this.state.text}
															<br /> <br />
															<a className="link" target="_blank" href={this.state.link}>{this.state.link}</a>
														</div>
														:
														<div className="postTextContent">{this.state.text}</div>
													}

												</div>

												{this.state.imageUrl == undefined || this.state.imageUrl == '' ?
													null
													:
													<div className="postImage">
														<img src={this.state.imageUrl} alt="post image" />
													</div>
												}

											</div>



											<div className="postTags">
												<span className="msgTime">{date.fromNow()}</span>
												{tags}
											</div>

											<div className="postFooter">
												<Row>

													<Col className="commentCol">
														<div className="commentWrapper">
															<FontAwesomeIcon icon={faComment} />
															<div>{this.state.amountOfComments}</div>
														</div>
													</Col>

													<Col className="topicCol">
														<div className="postTopic postDetails">{topic}</div>
													</Col>


												</Row>
											</div>

										</div>
									</div>

									<InfoModal show={this.state.showInfoModal} closeInfoModal={this.closeInfoModal} body={this.state.infoModalText} />


									{comments.length <= 0 && this.state.errorText == '' ?
										<div className="infoText">
											Var den första att kommentera!
										</div>
										:
										comments
									}

									<ViewportBlock show={this.state.showMoreLoader}
										onEnterViewport={() => this.onLoadVisible()} />

								</div>
							</div>

							<ChatFooter createComment={this.createComment} setRespondData={this.setRespondData}
								onUploadChange={this.onUploadChange} onVoiceRecord={this.onVoiceRecord} respondUsername={this.state.respondUsername}
								openInfoModal={this.openInfoModal} permissionText={this.props.intl.formatMessage({ id: 'allowPermission' })} />
						</div>

						{this.state.showUserList ? (
							<UserList roomId={this.state.roomId} langUrl={this.props.langUrl} />
						) : null}


					</div>
				</div>

			</div>
		);
	}
}

function mapStateToProps(state) {
	const { loggedIn, allowDm } = state
	return {
		loggedIn: loggedIn,
		allowDm: allowDm,
		globalState: state
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		reset: reset,
		addRoomData: addRoomData,
		updateRoomData: updateRoomData,
		removeRoomData: removeRoomData,
		addRoom: addRoom,
		removeRoom: removeRoom,
		resetUnread: resetUnread,
		setAllowDm: setAllowDm,
		addBlockedUsername: addBlockedUsername,
		removeBlockedUsername: removeBlockedUsername,
		refreshStateData: refreshStateData
	},
		dispatch
	);
}

export default injectIntl(connect(
	mapStateToProps,
	mapDispatchToProps
)(PostContentInner));

const Block = (props, { inViewport: boolean }) => {
	const { inViewport, forwardedRef } = props;
	const color = inViewport ? '#217ac0' : '#ff9800';
	const text = inViewport ? 'In viewport' : 'Not in viewport';
	return (
		<div className={props.show ? 'viewport-block' : 'hidden'} ref={forwardedRef}>
			{/*}<Spinner animation="border" size="sm" variant="primary" />{*/}
			<div className='dcLoader' />
			<p className="loadingMsgsText">Laddar mer</p>
		</div>
	);
};

const ViewportBlock = handleViewport(Block, /** options: {}, config: {} **/);
