import React from "react";
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import AppConfig from '../appconfig';
import UserListItem from './userListItem';
import Spinner from 'react-bootstrap/Spinner';
import handleViewport from 'react-in-viewport';
import {
  addBlockedUsername,
  removeBlockedUsername
} from "../state/actions";
import { FormattedMessage } from 'react-intl';


class UserList extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			isLoading: true,
			offlineUsers: [],
			onlineUsers: [],
			fetchedNames : 0,
			showMoreUsersLoader: false
		};

		this.getRoomUsers = this.getRoomUsers.bind(this);
	}

	componentDidMount() {
		this.getRoomUsers();
	}

	getRoomUsers() {
		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');
		if (userId == undefined || userId == '' ||
			sessionId == undefined || sessionId == '') {
			// Not logged in.
			return false;
		}

		let url = AppConfig.API_URL + 'getRoomUsers';

		url += ('/' + userId);
		url += ('/' + sessionId);
		url += ('/' + this.props.roomId);
		url += ('/' + this.state.fetchedNames);

	 	fetch(url, {
	 		credentials: 'include'
	 	})
		.then(res => res.json())
		.then(
			result => {
				if (result.success) {
					// Add the fetched names to the previous names, and show the loader if more than 29 users have been fetched since there may be more.
					this.setState({
						isLoading: false,
						offlineUsers: this.state.offlineUsers.concat(result.offlineUsers),
						onlineUsers: result.onlineUsers,
						fetchedNames: this.state.fetchedNames + result.fetchedNames,
						showMoreUsersLoader: result.fetchedNames > 29 ? true : false
					});

				} else {
					if (result.errorCode == 101) {
						window.location.reload();
					}
				}
				//this.setShowSignInButton(true);
			},
			error => {
				
			}
		);

		return false;
	}

	onLoadVisible() {
		this.getRoomUsers();
	}

 	render() {
		return (
			<div className="col-md-4 col-xl-3 userList">
				<div className="card mb-sm-3 mb-md-0 contacts_card">
					<div className="cardHeader">
						<div className=""><FontAwesomeIcon icon={faUsers} /></div> <FormattedMessage id="users" />
						{/*}
						<div className="input-group">
							<input type="text" placeholder="Search..." name="" className="form-control search" />
							<div className="input-group-prepend">
								<span className="input-group-text search_btn"><FontAwesomeIcon icon={faSearch} /></span>
							</div>
						</div>
						{*/}
					</div>

					{!this.state.isLoading ?

						<div className="card-body contacts_body">

							{this.state.onlineUsers.length > 0 ? 
								(

									<ul className="contacts">
										<li>Online</li>

										{this.state.onlineUsers.map((user, index) => (
											<UserListItem key={user.username} username={user.username} status="online" avatarUrl={user.avatarUrl}
												addBlockedUsername={this.props.addBlockedUsername} removeBlockedUsername={this.props.removeBlockedUsername} 
												langUrl={this.props.langUrl} />
									  	))}

										<div className="dividerHr"><span className="dividerSpan"></span></div>
									</ul>

								) : null}
								
								{this.state.offlineUsers.length > 0 ? 
								(

									<ul className="contacts">
										<li>Offline</li>
										{this.state.offlineUsers.map((user, index) => (
											<UserListItem key={user.username} username={user.username} status="offline" avatarUrl={user.avatarUrl}
												addBlockedUsername={this.props.addBlockedUsername} removeBlockedUsername={this.props.removeBlockedUsername}
												langUrl={this.props.langUrl} />
									  	))}

										<ViewportBlock show={this.state.showMoreUsersLoader} onEnterViewport={() => this.onLoadVisible()} />
									</ul>


								) : null}
						</div>
					: 
						
						<div className={this.state.isLoading ? 'dcLoader medium' : 'hidden'} />
					}

					{/*}<div className="card-footer"></div>{*/}
				</div>
			</div>
		);
		
		
	 }
}

function mapStateToProps(state) {
	const { loggedIn } = state
	return { loggedIn: loggedIn }
}

function mapDispatchToProps(dispatch) {
	  return bindActionCreators(
    {
     	addBlockedUsername: addBlockedUsername,
		removeBlockedUsername: removeBlockedUsername
    },
    dispatch
  );}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UserList);

const Block = (props, { inViewport: boolean }) => {
  const { inViewport, forwardedRef } = props;
  const color = inViewport ? '#217ac0' : '#ff9800';
  const text = inViewport ? 'In viewport' : 'Not in viewport';
  return (
    <div className={props.show ? 'viewport-block' : 'hidden'} ref={forwardedRef}>
		{/*}<Spinner animation="border" size="sm" variant="primary" />{*/}
		<div className='dcLoader' />
		<p className="loadingMsgsText loadingUsers">Laddar mer</p>
    </div>
  );
};

const ViewportBlock = handleViewport(Block, /** options: {}, config: {} **/);