import React, { useState } from "react"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FormattedMessage } from 'react-intl';


export default function InfoModal(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

	return (
		<Modal show={props.show} onHide={props.closeInfoModal} size="sm"
			aria-labelledby="contained-modal-title-vcenter"
			centered>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>{props.body}</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={props.closeInfoModal}>
					<FormattedMessage id="close" />
				</Button>
			</Modal.Footer>
		</Modal>
	);
}