import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointUp } from '@fortawesome/free-regular-svg-icons';
import UserContent from './userContent';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import SEO from "../components/seo";
import PageWrapper from "../components/pageWrapper";
import 'intl/locale-data/jsonp/sv';
import i18nMessages from '../i18n/sv.json';


class User extends React.Component {

 	render() {
 		if (this.props.initialLoader) {
 			return (
 				<div className="chatPageWrapper page">
					<div className='dcLoader medium preLoader' />
 				</div>
 			);
 		} else {
	    	return (
				<PageWrapper location={this.props.location} lang="sv" i18nMessages={i18nMessages}>
					<UserContent username={this.props.username} langUrl="/sv" />
				</PageWrapper>
			);
 		}	
	}
}

function mapStateToProps(state) {
	const { loggedIn, initialLoader } = state
	return { loggedIn: loggedIn,
			 initialLoader: initialLoader }
}


export default connect(
	mapStateToProps
)(User);
