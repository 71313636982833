import React from "react"
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AppConfig from '../appconfig';
import {
  setUnread,
  removeConversation,
  reduceTotalUnread
} from "../state/actions";
import anonUser from '../../static/anon_user_purple.png';


class DrawerMessageItem extends React.Component {

	componentDidMount() {
		// Save the unread amount to the global state
		let unreadStateName = "unreadconversation"+this.props.name.toLowerCase();
		
		this.props.setUnread(unreadStateName, this.props.unread);
	}

	handleHideConversation(event, recipientName) {
		event.preventDefault();
		event.stopPropagation();

		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');
		if (userId == undefined || userId == '' ||
			sessionId == undefined || sessionId == '') {
			// Not logged in.
			return false;
		}

		this.props.removeConversation(recipientName);

		let url = AppConfig.API_URL + 'hideConversation';

		url += ('/' + userId);
		url += ('/' + sessionId);
		url += ('/' + recipientName);

	 	fetch(url, {
	 		credentials: 'include'
	 	})
		.then(res => res.json())
		.then(
			result => {
				if (result.success) {
					let unreadStateName = "unreadconversation"+recipientName.toLowerCase();			
					this.props.reduceTotalUnread(unreadStateName);
				} else {
					if (result.errorCode == 3) {
						// User not found, remove conversaion.
						let unreadStateName = "unreadconversation"+recipientName.toLowerCase();			
						this.props.reduceTotalUnread(unreadStateName);
					} else if (result.errorCode == 101) {
						window.location.reload();
					}
				}

			},
			error => {
				
			}
		);

		return false;
	}


 	render() {
		let unreadStateName = "unreadconversation"+this.props.name.toLowerCase();

		let unreadState = this.props.globalState[unreadStateName];
		let unread = unreadState != undefined ? unreadState : 0;

		let imgSrc = this.props.avatarUrl;
		if (imgSrc == undefined || imgSrc == '') {
			imgSrc = anonUser;
		}

 		return (
	 		<ListItem className="drawerListItem" button>
	 			<div className="d-flex bd-highlight">
					<div className="imgContainer small">
						<img src={imgSrc} className="rounded-circle" />
						<span className={'onlineIcon ' + this.props.status}></span>
					</div>
					<div className="userInfo">
						<span></span>
					</div>
				</div>

				<ListItemText primary={this.props.name} />
				<div className={unread == 0 ? 'unreadIcon visibilityHidden' : 'unreadIcon'}> <Badge variant="info">{unread}</Badge></div>
				<div className="removeCross" onClick={(event) => this.handleHideConversation(event, this.props.name)}><FontAwesomeIcon icon={faTimes} /></div>
		  </ListItem>
		);
	 }
}

function mapStateToProps(state) {
	return { 
		globalState: state
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		setUnread: setUnread,
		removeConversation: removeConversation,
		reduceTotalUnread: reduceTotalUnread
	},
	dispatch
	);
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DrawerMessageItem);