import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import styles from "./uploadButton.module.css";


export default props => 
	<div className='input-group-append'>
		<label htmlFor='singleUpload'>
			<div className='input-group-text footerBtn attachBtn'>

				<FontAwesomeIcon icon={faImage} />

				<input type='file' id='singleUpload' onChange={props.onChange} />
			</div>
		</label>
	</div>

