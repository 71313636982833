import React from 'react';
import { navigate } from "gatsby";
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import DrawerRoomItem from './drawerRoomItem';
import DrawerMessageItem from './drawerMessageItem';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { SwipeableDrawer } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faDoorOpen, faAngleDown, faAngleUp, faHeart, faBullhorn, faComment, faClock, faQuestionCircle, faInfoCircle, faBoxTissue } from '@fortawesome/free-solid-svg-icons';
import Collapse from '@material-ui/core/Collapse';
import { Link } from "gatsby";
import Spinner from 'react-bootstrap/Spinner';
import { injectIntl, FormattedMessage } from 'react-intl';
import SignInComponent from './signInComponent'
import Button from 'react-bootstrap/Button';
import SignInButton from './signInButton';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	appBar: {
		[theme.breakpoints.up('sm')]: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: drawerWidth,
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	// necessary for content to be below app bar
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},

	drawerContainer: {
		overflow: 'auto',
	},


}));

const DrawerMenu = (props) => {
	const { window } = props;
	const classes = useStyles();
	const theme = useTheme();
	const [messagesOpen, setMessagesOpen] = React.useState(true);


	const handleMessagesClick = () => {
		setMessagesOpen(!messagesOpen);
	};


	const container = window !== undefined ? () => window().document.body : undefined;
	const iOS = typeof window !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);


	let mainMenu = (
		<div>
			<Link onClick={() => props.setMobileDrawerOpen(false)} to="/latest">
				<ListItem button>
					<ListItemIcon><FontAwesomeIcon icon={faClock} /></ListItemIcon>
					<ListItemText primary="Senaste" />
				</ListItem>
			</Link>

			<Link onClick={() => props.setMobileDrawerOpen(false)} to="/liked">
				<ListItem button>
					<ListItemIcon><FontAwesomeIcon icon={faHeart} /></ListItemIcon>
					<ListItemText primary="Gillas" />
				</ListItem>
			</Link>

			<Link onClick={() => props.setMobileDrawerOpen(false)} to="/discussed">
				<ListItem button>
					<ListItemIcon><FontAwesomeIcon icon={faComment} /></ListItemIcon>
					<ListItemText primary="Diskuteras" />
				</ListItem>
			</Link>

			<Link onClick={() => props.setMobileDrawerOpen(false)} to="/parties">
				<ListItem button>
					<ListItemIcon><FontAwesomeIcon icon={faBullhorn} /></ListItemIcon>
					<ListItemText primary="Partier" />
				</ListItem>
			</Link>
		</div>
	);

	let supportMenu = (
		<div>
			{/*}
  		<Link onClick={() => props.setMobileDrawerOpen(false)} className="createMenu" to="/election">
				<ListItem button>
					<ListItemIcon className="createIcon"><FontAwesomeIcon icon={faBoxTissue} /></ListItemIcon>
					<ListItemText primary="Val 2022" />
				</ListItem>
			</Link>
			{*/}

			<Link onClick={() => props.setMobileDrawerOpen(false)} className="createMenu" to="/createPost">
				<ListItem button>
					<ListItemIcon className="createIcon"><FontAwesomeIcon icon={faHeart} /></ListItemIcon>
					<ListItemText primary="Skapa" />
				</ListItem>
			</Link>

			<Link onClick={() => props.setMobileDrawerOpen(false)} to='/help'>
				<ListItem button>
					<ListItemIcon><FontAwesomeIcon icon={faQuestionCircle} /></ListItemIcon>
					<ListItemText primary="Hjälp" />
				</ListItem>
			</Link>

			<Link onClick={() => props.setMobileDrawerOpen(false)} to='/about'>
				<ListItem button>
					<ListItemIcon><FontAwesomeIcon icon={faInfoCircle} /></ListItemIcon>
					<ListItemText primary="Om" />
				</ListItem>
			</Link>
		</div>
	);

	return (
		<div className={classes.root}>
			<CssBaseline />

			<nav className={classes.drawer} aria-label="mailbox folders">
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Hidden smUp implementation="css">
					<SwipeableDrawer
						container={container}
						disableSwipeToOpen={false}
						disableBackdropTransition={!iOS} disableDiscovery={iOS}
						variant="temporary"
						anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						open={props.mobileDrawerOpen}
						onClose={props.toggleMobileDrawerOpen}
						onOpen={props.toggleMobileDrawerOpen}
						swipeAreaWidth={30}
						classes={{
							paper: classes.drawerPaper,
						}}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>

						<div className="drawer">
							<div className={classes.toolbar} />
							{props.loading ?

								<div className={props.loading ? 'dcLoader medium' : 'hidden'} />
								:
								<div>
									<List dense={true}>

										{mainMenu}

									</List>

									<Divider />
									<Divider />

									<List dense={true}>

										{supportMenu}

										<div onClick={() => props.setMobileDrawerOpen(false)}>
											<SignInButton />
										</div>

									</List>
								</div>
							}

						</div>

					</SwipeableDrawer>
				</Hidden>
				<Hidden xsDown implementation="css">
					<Drawer
						classes={{
							paper: classes.drawerPaper,
						}}
						variant="permanent"
						open
					>

						<div className="drawer">
							<div className={classes.toolbar} />
							{props.loading ?

								<div className={props.loading ? 'dcLoader medium' : 'hidden'} />
								:
								<div>
									<List dense={false}>

										{mainMenu}

									</List>

									<Divider />
									<Divider />

									<List dense={true}>

										{supportMenu}

										<div onClick={() => props.setMobileDrawerOpen(false)}>
											<SignInButton />
										</div>
									</List>
								</div>
							}
						</div>

					</Drawer>
				</Hidden>
			</nav>
			<main className={classes.content}>
				<div className={classes.toolbar} />

			</main>
		</div>
	);
}

DrawerMenu.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export default injectIntl(DrawerMenu);