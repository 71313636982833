import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointUp } from '@fortawesome/free-regular-svg-icons'
import PostContent from './postContent';
import { connect } from "react-redux"
import PageWrapper from "../components/pageWrapper";
import 'intl/locale-data/jsonp/sv';
import i18nMessages from '../i18n/sv.json';


class Post extends React.Component {

 	render() {
 		if (this.props.initialLoader) {
 			return (
 				<div className="chatPageWrapper page">
					<div className='dcLoader medium preLoader' />
 				</div>
 			);
 		} else {
        	return (
				<PageWrapper location={this.props.location} lang="sv" i18nMessages={i18nMessages}>
					<PostContent postTitle={this.props.postTitle} langUrl="" />
				</PageWrapper>
			);
 		}		
	}
}

function mapStateToProps(state) {
	const { loggedIn, initialLoader } = state
	return { loggedIn: loggedIn,
			initialLoader: initialLoader }
}

export default connect(
	mapStateToProps
)(Post);

