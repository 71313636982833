import React, { useState, useRef } from "react"
import { navigate } from 'gatsby';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import AppConfig from '../appconfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faBan, faEnvelope, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage, injectIntl } from 'react-intl';

// For all languages
import moment from'moment/min/moment-with-locales';
//import moment from'moment';
//import 'moment/locale/sv';

export default function UserMenu(props) {

	const [loadingError, setLoadingError] = React.useState(false);
	const [showModMenu, setShowModMenu] = useState(false);
	const [showExtendedMenu, setShowExtendedMenu] = useState(false);
	const [isBlocked, setIsBlocked] = useState(false);
	const [isSilenced, setIsSilenced] = useState(false);
	const [isRemoved, setIsRemoved] = useState(false);


	const handlePopoverToggle = (username) => {
		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'getUserMenuOptions';

		fetch(url, {
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				userId: userId,
				sessionId: sessionId,
				targetUsername: username,
				roomId: props.roomId
			})
		})
		.then(res=>res.json())
		.then(
			result => {
				if (result.success) {
					setIsBlocked(result.userBlocked);
					setShowModMenu(result.modMenu);
					setShowExtendedMenu(result.extendedMenu);
					setIsRemoved(result.removed);
					setIsSilenced(result.silenced);
				} else {
					if (result.errorCode == 101) {
						window.location.reload();
					}
				}
			},
			error => {
				console.log("Internal error");
			}
		);

		return false;
	}

	const handleProfileClick = (username) => {
		navigate('/user/' + username);
	}

	const handleMessageClick = (username) => {
		navigate('/message/' + username);
	}

	const handleBlockClick = (username) => {
 		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'setUserBlock';

		fetch(url, {
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				userId: userId,
				sessionId: sessionId,
				blockUsername: username,
				block: !isBlocked
			})
		})
		.then(res=>res.json())
		.then(
			result => {
				if (result.success) {
					setIsBlocked(!isBlocked);
					if (!isBlocked) {
						props.addBlockedUsername(username);
					} else {
						props.removeBlockedUsername(username);
					}
				} else {
					if (result.errorCode == 101) {
						window.location.reload();
					}
				}
			},
			error => {
				console.log("Internal error");
			}
		);

		return false;
	}

	const handleSilenceClick = (username) => {
 		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'setUserSilence';

		fetch(url, {
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				userId: userId,
				sessionId: sessionId,
				silenceUsername: username,
				silence: !isSilenced
			})
		})
		.then(res=>res.json())
		.then(
			result => {
				if (result.success) {
					setIsSilenced(!isSilenced);
				} else {
					console.log("Error");
				}
			},
			error => {
				console.log("Internal error");
			}
		);

		return false;
	}

	const handleIpBlockClick = (username) => {
 		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'setIpBlock';

		fetch(url, {
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				userId: userId,
				sessionId: sessionId,
				blockUsername: username
			})
		})
		.then(res=>res.json())
		.then(
			result => {
				if (result.success) {
					if (result.status == "success") {
						alert("IP banned!");
					} else if (result.status == "duplicate") {
						alert("IP already banned!");
					} else {
						alert("Error");
					}
				} else {
					alert("Error");
				}
				
			},
			error => {
				console.log("Internal error");
			}
		);

		return false;
	}

	const handleRemoveClick = (username) => {
		var confirmRemoval = window.confirm("Are you sure you want to remove " + username + "? Note that this can NOT be reversed.");
		if (!confirmRemoval) {
			return;
		}
		
 		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'rmUser';

		fetch(url, {
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				userId: userId,
				sessionId: sessionId,
				rmUsername: username
			})
		})
		.then(res=>res.json())
		.then(
			result => {
				if (result.success) {
					alert("User removed!");
				} else {
					if (result.status == "not found") {
						alert("User not found");
					} else {
						alert("Error");
					}
				}
				
			},
			error => {
				console.log("Internal error");
			}
		);

		return false;
	}

	const handleRemoveFromRoomClick = (username) => {
		if (isRemoved) {
			return;
		}
		var confirmRemoval = window.confirm(props.intl.formatMessage({ id: 'removeConfirmation' }) 
				+ ' ' + username + ' ' + props.intl.formatMessage({ id: 'fromRoom' }) + '?');
		if (!confirmRemoval) {
			return;
		}
		
 		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'removeFromRoom';

		fetch(url, {
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				userId: userId,
				sessionId: sessionId,
				rmUsername: username,
				roomId: props.roomId
			})
		})
		.then(res=>res.json())
		.then(
			result => {
				if (result.success) {
					setIsRemoved(true);
				} else {
					if (result.errorCode == 1) {
						alert(props.intl.formatMessage({ id: 'alreadyRemoved' }));
					} else if (result.errorCode == 107) {
						alert(props.intl.formatMessage({ id: 'userNotFound' }));
					} else if (result.errorCode == 108) {
						alert(props.intl.formatMessage({ id: 'notAllowed' }));
					} else {
						alert(props.intl.formatMessage({ id: 'errorTryLater' }));
					}
				}
				
			},
			error => {
				alert(props.intl.formatMessage({ id: 'errorTryLater' }));
			}
		);

		return false;
	}


	const popover = (
		<Popover className="userPopover">
			<Popover.Body>
				<div className="action_menu">
					<ul>
						<li onKeyDown={() => handleProfileClick( props.username )} onClick={() => handleProfileClick( props.username )}>
							<FontAwesomeIcon icon={faUserCircle} /> Profil
						</li>
					{/*}
						<li onKeyDown={() => handleMessageClick( props.username )} onClick={() => handleMessageClick( props.username )}>
							<FontAwesomeIcon icon={faEnvelope} /> Meddelande
						</li>
					{*/}
						<li onKeyDown={() => handleBlockClick( props.username )} onClick={() => handleBlockClick( props.username )}>
							<FontAwesomeIcon icon={faBan} /> {isBlocked ? 'Avblockera' : 'Blockera'}
						</li>
						
						{showExtendedMenu ? 
							<li onKeyDown={() => handleRemoveFromRoomClick( props.username )} onClick={() => handleRemoveFromRoomClick( props.username )}>
								<FontAwesomeIcon icon={faExclamationCircle} /> {
									isRemoved ? 'Redan borttagen' : 'Ta bort från rum'
									}
							</li>
							: 
							null}

						{showModMenu ? 
							<div>
								<li onKeyDown={() => handleSilenceClick( props.username )} onClick={() => handleSilenceClick( props.username )}>
									<FontAwesomeIcon icon={faExclamationCircle} /> {isSilenced ? 'Unsilence' : 'Silence'}
								</li>
								<li onKeyDown={() => handleIpBlockClick( props.username )} onClick={() => handleIpBlockClick( props.username )}>
									<FontAwesomeIcon icon={faExclamationCircle} /> Ban IP
								</li>
								<li onKeyDown={() => handleRemoveClick( props.username )} onClick={() => handleRemoveClick( props.username )}>
									<FontAwesomeIcon icon={faExclamationCircle} /> Remove
								</li>
							</div>
							: 
							null}
					</ul>
				</div>
			</Popover.Body>
		</Popover>
	);

	return (
		<Overlay onEnter={() => handlePopoverToggle(props.username)} show={props.show} placement={props.placement} 
			rootClose={true} onHide={() => props.setShow(!props.show)} target={props.target.current}>
				{popover}
		</Overlay>
	);
	
}
