// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

import 'bootstrap/dist/css/bootstrap.min.css';

import "./src/styles/global.css"
import wrapWithProvider from "./wrap-with-provider"

const React = require("react")
const Layout = require("./src/components/layout").default


export const wrapRootElement = wrapWithProvider

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout title={'Dinchat'} {...props}>{element}</Layout>
}

export const onClientEntry = () => {
  if (window.location.pathname === '/en') {
    window.location.pathname = `/`
  }
}