import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointUp } from '@fortawesome/free-regular-svg-icons';
import UserContentInner from './userContentInner';
import { connect } from "react-redux"
import SEO from "../components/seo";
import { FormattedMessage, injectIntl } from 'react-intl';


class UserContent extends React.Component {

 	render() {
		if (this.props.loggedIn) {
			return (
				<UserContentInner username={this.props.username} langUrl={this.props.langUrl} />
			);
		} else {
			return(
				<div className="chatPageWrapper page">
					<SEO 
		                title={this.props.roomName} 
						description={this.props.intl.formatMessage({ id: 'userSeoDesc' })}
		                pagePath="/user"
		            />
					<div className="errorText"><FormattedMessage id="loginShowUser" /> {this.props.username} <br /> <FontAwesomeIcon icon={faHandPointUp} /></div>
				</div>
			);
		}
	}
}

function mapStateToProps(state) {
	const { loggedIn, initialLoader } = state
	return { loggedIn: loggedIn,
			 initialLoader: initialLoader }
}


export default injectIntl(connect(
	mapStateToProps
)(UserContent));
