import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationArrow, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faGrinWink } from '@fortawesome/free-regular-svg-icons'
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart';
import UploadButton from './uploadButton';
import { checkText } from 'smile2emoji';
import AudioRecorder from "../components/audioRecorder";



export default class ChatFooter extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			showEmojiPicker: false,
			newMessage: '',
		};

		this.toggleEmojiPicker = this.toggleEmojiPicker.bind(this);
		this.addEmoji = this.addEmoji.bind(this);
		this.updateNewMessage = this.updateNewMessage.bind(this);
		this.handleSendMessage = this.handleSendMessage.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
	}


	handleSendMessage() {
		let textValue = this.state.newMessage;

		if (textValue == undefined || textValue == '' || textValue == ' ') {
			return false;
		}

		this.setState({
			newMessage: ''
		});

		this.props.createComment(textValue);
	}

	onKeyDown(event) {
		if (event.key === 'Enter' && event.shiftKey == false) {
			event.preventDefault();
			event.stopPropagation();
			this.handleSendMessage();
		}
 	}

	toggleEmojiPicker() {
		this.setState({
			showEmojiPicker: !this.state.showEmojiPicker
		});
	}

	addEmoji(emoji) {
		const { newMessage } = this.state;
		const text = `${newMessage}${emoji.native}`;

		this.setState({
			newMessage: text,
			showEmojiPicker: false,
		});
	}

	updateNewMessage(evt) {
		let textInput = evt.target.value;
		let textChecked = checkText(textInput);
		
		this.setState({
			newMessage: textChecked
		});
	}

	adjustHeight(e){
		e.target.style.height = "1px";
  		e.target.style.height = (e.target.scrollHeight)+"px";
	}


 	render() {
		return (
			<div className="chatFooter">
				{this.props.respondUsername != undefined &&
					<div className="responding">
						Svarar {this.props.respondUsername}
						<div className="removeCross" onClick={() => this.props.setRespondData(undefined, 0)}>
 							<FontAwesomeIcon icon={faTimes} />
						</div>
					</div>
				}

				<div className="input-group compose">
					{/*}
					<div className="input-group-append">
						<div className="input-group-text attach_btn">
							<FontAwesomeIcon icon={faPaperclip} />
						</div>
					</div>
					


					<AudioRecorder onVoiceRecord={this.props.onVoiceRecord} openInfoModal={this.props.openInfoModal} permissionText={this.props.permissionText} />
					{*/}

					<UploadButton onChange={this.props.onUploadChange} />

					<textarea onKeyUp={this.adjustHeight} maxLength="2000" rows={1} value={this.state.newMessage} onChange={this.updateNewMessage} 
						onKeyDown={(e) => this.onKeyDown(e)} disabled={this.props.disabled} className="form-control typeMsg" placeholder={'Börja skriv här...'}>
					</textarea>
					
					<div className="pickEmoji">
						{this.state.showEmojiPicker ? (
			            	<Picker showPreview={false} emoji='' set="apple" title='' onSelect={this.addEmoji} />
			            ) : null}
		            </div>

					<div className="toogleEmoji">
						<span onClick={this.toggleEmojiPicker} className="input-group-text footerBtn">
							<FontAwesomeIcon icon={faGrinWink} />
						</span>
					</div>

					<div className="input-group-append">
						<span onClick={this.handleSendMessage} className="input-group-text footerBtn sendBtn">
							<FontAwesomeIcon icon={faLocationArrow} />
						</span>
					</div>

				</div>
			</div>
		);
		
		
	 }
}

