import React from "react";
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import DrawerContainer from "./drawerContainer";
import Drawer from "./drawer";
import {
  setLoggedIn,
  setMobileDrawerOpen
} from "../state/actions";


class SideBarContainer extends React.Component {
	constructor(props) {
		super(props);

		this.toggleMobileDrawerOpen = this.toggleMobileDrawerOpen.bind(this);
	}

	toggleMobileDrawerOpen() {
		this.props.setMobileDrawerOpen(!this.props.mobileDrawerOpen);
	};

	render() {
		if (this.props.loggedIn) {
			return (
				<DrawerContainer langUrl={this.props.langUrl} />
			);
		} else {
			return (
				<Drawer loading={this.props.initialLoader} mobileDrawerOpen={this.props.mobileDrawerOpen} toggleMobileDrawerOpen={this.toggleMobileDrawerOpen} 
					setMobileDrawerOpen={this.props.setMobileDrawerOpen} />
			);
		}
		
		
		
	 }
}

function mapStateToProps(state) {
	const { loggedIn, mobileDrawerOpen, initialLoader } = state
	return { 
		loggedIn: loggedIn,
		mobileDrawerOpen: mobileDrawerOpen,
		initialLoader: initialLoader
	}
}

function mapDispatchToProps(dispatch) {
	  return bindActionCreators(
	{
	  setLoggedIn: setLoggedIn,
	  setMobileDrawerOpen: setMobileDrawerOpen
	},
	dispatch
  );}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SideBarContainer);