import React from "react"
import AppConfig from '../appconfig';
import SignInModal from './signInModal';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import {
  setLoggedIn,
  reset,
  addRoomData,
  addConversationData,
  addConversation,
  addUnread,
  addToTotalUnread,
  setInitialLoader,
  removeConversation,
  removeUserMsgs,
  updateConversation,
  setConversations,
  setShowSignInModal
} from "../state/actions";
import SignInButton from './signInButton';
import InfoModal from "./InfoModal";
import { FormattedMessage, injectIntl } from 'react-intl';
import msgSound from '../../static/sounds/newmsg.mp3';



class SignInComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showSignInButton: false,
			showRegister: false,
			userId: '',
			sessionId: '',
			username: '',
			showInfoModal: false,
			infoModalText: '',
			seconds: 1,
			reconnectTime: 1,
			beenDisconnected: false
		};

		this.timer = 0;
		this.connectWebSocket = this.connectWebSocket.bind(this);
		this.disconnectWebSocket = this.disconnectWebSocket.bind(this);
		this.closeInfoModal = this.closeInfoModal.bind(this);
	}


	connectWebSocket(userId, sessionId, username) {
		let url = window.location.href.includes("localhost")
			? "ws://localhost:8088"
			: "wss://api.dinchat.com";
		var socket = new WebSocket(
			url + "/websocket/chat/" + userId + "/" + sessionId + "/" + username + "/"
		);

		this.setState({
			userId: userId,
			sessionId: sessionId,
			username: username
		});

		this.audio = new Audio(msgSound);
		this.audio.load();

		socket.onmessage = message => {
			let parsedMessage = JSON.parse(message.data);
			let data = parsedMessage.data;

			if (parsedMessage.action === "roommessage") {
				
				if (data.sessionId == sessionId) {
					// The user's own message sent by this session, already added on send.
					return false;
				}

				let blockedUsernames = this.props.globalState.blockedUsernames;
				if (blockedUsernames != undefined && blockedUsernames.includes(data.username)) {
					// The user has blocked the sending user.
					return false;
				}
				
				// Add message to room state.
			
				// Get the saved room data from the state.
				let roomStateName = "roomdata"+data.roomName.toLowerCase();
				let roomState = this.props.globalState[roomStateName];

				// Get the previous messages from the state.
				let totalMessages = [];
				if (roomState != undefined) {
					totalMessages = roomState.messages;
				}

				// Create a message from the input and add to messages in state.	
				let message = {
					id: data.messageId,
					key: data.messageId,
					contents: data.messageContents,
					created: data.created,
					userId: data.userId,
					username: data.username,
					type: data.type,
					avatarUrl: data.avatarUrl
				}
				totalMessages.push(message);

				// Set the data for the roomState. 
				let roomData = {
					roomId: data.roomId,
					roomName: data.roomName,
					messages: totalMessages,
					lastId: data.messageId,
					showMoreMsgsLoader: true
				}

				if (roomState != undefined) {
					// There was a previous roomState, use the data from the old state to not overwrite it.
					roomData.roomDesc = roomState.roomDesc;
					roomData.lastId = roomState.lastId;
					roomData.showMoreMsgsLoader = roomState.lastId > -1 ? true : false;
					roomData.imagesToLoad = roomState.imagesToLoad;
				}
				
				this.props.addRoomData(roomStateName, roomData);

				if (window.location.pathname.indexOf(data.roomName.toLowerCase()) > -1 && window.location.pathname.indexOf('room') > -1 ) {
					// The user is currently on the page for this message. Don't increase the unread count, update last read.
					this.setLastRead(1, data.roomId, null, userId, sessionId);
				} else {
					// Increase the unread count in the global state.
					let unreadStateName = "unreadroom" + data.roomName.toLowerCase();				
					this.props.addUnread(unreadStateName, 1);
				}

			} else if (parsedMessage.action === "directmessage") {
				if (data.sessionId == sessionId) {
					// The user's own message sent by this session, already added on send.
					return false;
				}

				let otherUsername;
				if (data.userId == userId) {
					// The user's own message, use recipient name for the state.
					otherUsername = data.recipientName;
				} else {
					// The message was sent by another, use the senders name for the state.
					otherUsername = data.username;
				}

				// Add message to conversation state.
			
				// Get the saved conversation data from the state.
				let conversationStateName = "conversationdata"+otherUsername.toLowerCase();
				let conversationState = this.props.globalState[conversationStateName];

				// Get the previous messages from the state.
				let totalMessages = [];
				if (conversationState != undefined) {
					totalMessages = conversationState.messages;
				}

				// Create a message from the input and add to messages in state.	
				let message = {
					id: data.messageId,
					key: data.messageId,
					contents: data.messageContents,
					created: data.created,
					userId: data.userId,
					username: data.username,
					type: data.type,
					avatarUrl: data.avatarUrl
				}
				totalMessages.push(message);

				// Set the data for the conversationState. 
				let conversationData = {
					conversationId: data.conversationId,
					messages: totalMessages,
					lastId: data.messageId,
					showMoreMsgsLoader: true
				}

				if (conversationState != undefined) {
					// There was a previous conversationState, use the data from the old state to not overwrite it.
					conversationData.lastId = conversationState.lastId;
					conversationData.showMoreMsgsLoader = conversationState.lastId > -1 ? true : false;
					conversationData.imagesToLoad = conversationState.imagesToLoad;
				}

				let conversations = this.props.globalState.conversations;
				let alreadyInList = false;

				for (var i = 0; i < conversations.length; i++) {
					let convo = conversations[i];
					if (convo.conversationId == data.conversationId) {
						alreadyInList = true;
						// Conversation is in the list, remove it then add it to the top.
						conversations.splice(i, 1);
						this.props.addConversation(convo);
						break;
					}
				}

				//if (data.newlyCreated) {
					// This is the first message in the conversation. Add it to the drawer if it's not there.
					
					if (!alreadyInList) {
						// Create the conversation for the list and set the recipient to online because he/she just sent it.
						let conversationObj = {
							conversationId: data.conversationId,
							recipientUsername: otherUsername,
							created: data.created,
							recipientLastSeen: 1,
							recipientAvatar: data.avatarUrl
						}
						this.props.addConversation(conversationObj);
					}
				//}
				
				this.props.addConversationData(conversationStateName, conversationData);

				let otherUsernameLowerCase = otherUsername.toLowerCase();

				if (window.location.pathname.indexOf('message') > -1 && window.location.pathname.indexOf(otherUsernameLowerCase) > -1) {
					// The user is currently on the page for this message. Don't increase the unread count, update last read.
					this.setLastRead(2, -1, data.conversationId, userId, sessionId);
				} else {
					// Increase the unread count in the global state.
					let unreadStateName = "unreadconversation" + otherUsernameLowerCase;					
					this.props.addUnread(unreadStateName, 1);
					this.props.addToTotalUnread(1);
				}

				// Play message sound
				if (this.props.globalState.dmSounds) {
					this.audio.play();
				}
			} else if (parsedMessage.action === "removeduser") {
				this.props.removeConversation(data.data);

				// Remove the users messages from all cached rooms
				Object.keys(this.props.globalState).map((stateName) => {
					if (stateName.includes('roomdata')) {
						this.props.removeUserMsgs(stateName, data.data);
					}
				});
			} else if (parsedMessage.action === "userstatus") {
				let conversations = this.props.globalState.conversations;
				for(var i = 0; i < conversations.length; i++) {
					if (conversations[i].recipientUsername == data.username) {
						conversations[i].recipientLastSeen = data.lastSeen;
						this.props.updateConversation(i, conversations[i]);
						break;
					}
				}
			} else if (parsedMessage.action === "refresh") {
				window.location.reload();
			}
		};

		socket.onopen = () => {
			//console.log("WebSocket opened");
			if (this.state.beenDisconnected) {
				// The connection was previously down, reload the page to fetch new data in case something was missed during downtime.
				window.location.reload();
			}

			// Reset the reconnect attempt values.
			this.setState({
				seconds: 1,
				reconnectTime: 1,
				showInfoModal: false
			});
		};

		socket.onclose = (event) => {
			//console.log("WebSocket closed: " + this.state.reconnectTime);
			if (!this.props.loggedIn) {
				return;
			}

			if (event.code == 1003) {
				// The socket was closed because the user is no longer logged in. Logout the client as well.
				//this.logoutUser();
				return;
			}

			this.setState({
				beenDisconnected: true
			});

			if (this.state.reconnectTime == 1) {
				// First reconnect attempt, try with starting any counter.
				this.connectWebSocket(userId, sessionId, username);

				// Increase the time to next reconnect attempt.
				let reconnectTime = this.state.reconnectTime * 5;
				this.setState({
					seconds: reconnectTime,
					reconnectTime: reconnectTime
				});
			} else {
				// This is not the first reconnect attempt, show modal and start countdown to next attempt.
				this.timer = setInterval(() => this.countDown(userId, sessionId, username), 1000);
			}

			//setTimeout(function() {
			//	this.connectWebSocket(userId, sessionId, username);
			//}.bind(this), 2000);
		};

		this.setState({ socket });
	}

	countDown(userId, sessionId, username) {		
		// Remove one second and set state to re-render.
		let secondsLeft = this.state.seconds - 1;
		this.setState({
			seconds: secondsLeft
		});

		// If the countdown is finished, try to reconnect.
		if (secondsLeft == 0) { 
			// Increase the time to next reconnect attempt.
			let reconnectTime = this.state.reconnectTime * 5;
			this.setState({
				seconds: reconnectTime,
				reconnectTime: reconnectTime,
				showInfoModal: true
			});

			clearInterval(this.timer);
			this.connectWebSocket(userId, sessionId, username);
		}
	}

	logoutUser() {
		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'logout';

		localStorage.removeItem("userId");
		localStorage.removeItem("sessionId");
		localStorage.removeItem("username");

		url += ('/' + userId);
		url += ('/' + sessionId);

		fetch(url, {
			credentials: 'include'
		})
		.then(res => res.json())
		.then(
			result => {
				if (result.success) {
					// User was logged out.
				} else {
					console.log("Already logged out.");
				}
			},
			error => {
				console.log("Error logging out.");
			}
		);

		this.props.reset();
		this.props.setInitialLoader(false);
		return false;
	}

	closeInfoModal(e) {
		this.setState({
			showInfoModal: false
		});
	}

	disconnectWebSocket() {
		const { socket } = this.state;
		socket.close();
	}

	
	hideModal() {
		this.props.setShowSignInModal(false);
		this.setShowRegister(false);
	}

	setShowRegister(value) {
		this.setState({
			showRegister: value
		});
	}

	setLastRead(type, roomId, conversationId, userId, sessionId) {
		let url = AppConfig.API_URL + 'setLastRead';

		fetch(url, {
			method: 'post',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				userId: userId,
				sessionId: sessionId,
				roomId: roomId,
				conversationId: conversationId,
				type: type
			})
		})
		.then(res=>res.json())
		.then(
			result => {
				if (result.success) {

				} else {
					console.log("Error");
				}
			},
			error => {
				console.log("Internal error");
			}
		);

		return false;
	}

	render() {
		return (
			<div className="userOptions">
				<InfoModal show={this.state.showInfoModal} closeInfoModal={this.closeInfoModal} 
					body={this.props.intl.formatMessage({ id: 'connectionFailed' }) + this.state.seconds + ' ' + this.props.intl.formatMessage({ id: 'seconds' })} />
				
				<SignInButton closeMenu={this.props.closeMenu} 
					connectWebSocket={this.connectWebSocket} disconnectWebSocket={this.disconnectWebSocket} langUrl={this.props.langUrl} sideMenu={this.props.sideMenu} />

				<SignInModal
					show={this.props.globalState.showSignInModal}
					onHide={() => this.hideModal()}
					setLoggedIn={() => this.props.setLoggedIn(true)}
					setShowRegister={(value) => this.setShowRegister(value)}
					// If a name has been entered on the front page, show the register form right away to let the user join.
					showRegister={(this.props.globalState.enteredName != undefined && this.props.globalState.enteredName != '') ? true : this.state.showRegister}
					connectWebSocket={this.connectWebSocket}
					langUrl={this.props.langUrl}
				/>
			</div>
		);
	 }
}

function mapStateToProps(state) {
	const { loggedIn } = state
	return { 
		loggedIn: loggedIn,
		globalState: state
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		setLoggedIn: setLoggedIn,
		reset: reset,
		addRoomData: addRoomData,
		addConversationData: addConversationData,
		addConversation: addConversation,
		addUnread: addUnread,
		addToTotalUnread: addToTotalUnread,
		setInitialLoader: setInitialLoader,
		removeConversation: removeConversation,
		removeUserMsgs: removeUserMsgs,
		updateConversation: updateConversation,
		setConversations: setConversations,
		setShowSignInModal: setShowSignInModal
	},
	dispatch
	);
}

export default injectIntl(connect(
	mapStateToProps,
	mapDispatchToProps
)(SignInComponent));