import React from 'react'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl';
import 'intl';
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';


const PageWrapper = ({ children, location, i18nMessages }) => {
	const defaultLangKey = 'en';
	const langs = ['en', 'sv'];
	const langKey = getCurrentLangKey(langs, defaultLangKey, location.pathname);
    const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, '/');
	const urlLang =  getUrlForLang(homeLink, location.pathname);
	const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, location.pathname)).map((item) => ({ ...item, link: item.link.replace(`/${defaultLangKey}/`, '/') }));

	return (
		<IntlProvider locale={"sv"} messages={i18nMessages}>
				{children}
		</IntlProvider>
	)

}

export default PageWrapper