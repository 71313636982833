import React from "react";
import { navigate } from 'gatsby';
import Button from 'react-bootstrap/Button';
import AppConfig from '../appconfig';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import {
  setLoggedIn,
  reset,
  addRoomData,
  setAllowDm,
  setBlockedUsernames,
  setInitialLoader,
  setAvatarUrl,
  setDmSounds,
  setPlusMember,
  setEnteredName,
  setShowSignInModal
} from "../state/actions";
import { FormattedMessage } from 'react-intl';

class SignInButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showSignInButton: false,
			showSuccessModal: false
		};

		this.toggleShowSuccessModal = this.toggleShowSuccessModal.bind(this);
		this.validateUser = this.validateUser.bind(this);
		this.validateLogin = this.validateLogin.bind(this);
		this.logoutUser = this.logoutUser.bind(this);
		this.setShowSignInButton = this.setShowSignInButton.bind(this);
		this.handleSignInClick = this.handleSignInClick.bind(this);
		this.handleAccountClick = this.handleAccountClick.bind(this);
		this.toggleShowModal = this.toggleShowModal.bind(this);
	}

	toggleShowModal() {
		this.props.setShowSignInModal(true);
		//this.setShowRegister(false);
	}

	componentDidMount() {
		this.validateUser();	
	}

	validateUser() {
		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');
		let username = localStorage.getItem('username');
		if (userId == undefined || userId == '' || 
			sessionId == undefined || sessionId == '') {
			// Not logged in.
			this.props.setLoggedIn(false);
			this.props.setInitialLoader(false);
			this.setShowSignInButton(true);
		} else {
			// Validate the login.
			if (!this.props.loggedIn) {
				this.validateLogin(userId, sessionId, username);
			} else {
				// The user's login was already validated. Show the buttons.
				this.setShowSignInButton(true);
			}

			/*
			// Logged in, check if the login time has expired.
			let currentTime = new Date();
			let loginExpiryTime = new Date(loginTokenExpires)
			if (currentTime.getTime() < loginExpiryTime.getTime()) {
				// The login time has not expired, validate the login and keep the user logged in with the same variables.
				this.validateLogin(userId, sessionId, username);
			} else {
				// The login time has expired, logout the user.
				this.logoutUser();
				this.setShowSignInButton(true);
			}
			*/
		}
	}

	validateLogin(userId, sessionId, username) {
		let url = AppConfig.API_URL + 'validateLogin';

		url += ('/' + userId);
		url += ('/' + sessionId);

		fetch(url, {
			credentials: 'include',
		})
		.then(res => res.json())
		.then(
			result => {
				if (result.success) {
					// User is still logged in.
					this.props.setAllowDm(result.allowDm);
					this.props.setDmSounds(result.dmSounds);
					this.props.setPlusMember(result.plusMember);
					this.props.setBlockedUsernames(result.blockedUsernames);
					this.props.setAvatarUrl(result.avatarUrl);
					//this.props.connectWebSocket(userId, sessionId, username);
					this.props.setLoggedIn(true);
					this.props.setInitialLoader(false);
				} else {
					localStorage.removeItem("userId");
					localStorage.removeItem("sessionId");
					localStorage.removeItem("username");

					this.props.setLoggedIn(false);
					this.props.setInitialLoader(false);
					// User is not logged in anymore.
				}
				this.setShowSignInButton(true);
			},
			error => {
				// Error, user should not be logged in.
				
				// TODO: Should these be removed?
				//localStorage.removeItem("userId");
				//localStorage.removeItem("sessionId");
				//localStorage.removeItem("username");
				this.props.setLoggedIn(false);
				this.props.setInitialLoader(false);
				this.setShowSignInButton(true);
			}
		);

		return false;
	}

	logoutUser() {
		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'logout';

		localStorage.removeItem("userId");
		localStorage.removeItem("sessionId");
		localStorage.removeItem("username");

		url += ('/' + userId);
		url += ('/' + sessionId);

		fetch(url, {
			credentials: 'include'
		})
		.then(res => res.json())
		.then(
			result => {
				if (result.success) {
					// User was logged out.
				}
			},
			error => {
				console.log("Error logging out.");
			}
		);

		//this.props.disconnectWebSocket();
		this.props.reset();
		this.props.setInitialLoader(false);
		navigate('/');
		return false;
	}

	toggleShowSuccessModal() {
		this.setState({
			showSuccessModal: !this.state.showSuccessModal
		});
	}


	setShowSignInButton(showButton) {
		this.setState({
			showSignInButton: showButton
		});
	}

	handleSignInClick() {
		this.props.setShowSignInModal(true);
		//this.setShowRegister(false);
	}

	handleAccountClick() {
		navigate('/account');
	}

	render() {
		if (this.state.showSignInButton) {

			if (this.props.loggedIn) {
				return (
					<div>
						<Button className="outlineBtn" size="sm" onClick={ this.handleAccountClick } variant="outline-info"><FormattedMessage id="account" /></Button>
						<Button size="sm" className="signout" onClick={ () => this.logoutUser() } variant="dark">Logga ut</Button>
					</div>
				);
			} else {
				return (
					<div>
						<Button className="outlineBtn" size="sm" onClick={ this.handleSignInClick } id="sign-in-button" variant="outline-info">Logga in</Button>
					</div>
				);
			}
		} else {
			return null;
		}
	 }
}

function mapStateToProps(state) {
	const { loggedIn } = state
	return { 
		loggedIn: loggedIn,
		globalState: state
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		setLoggedIn: setLoggedIn,
		reset: reset,
		addRoomData: addRoomData,
		setAllowDm: setAllowDm,
		setBlockedUsernames: setBlockedUsernames,
		setInitialLoader: setInitialLoader,
		setAvatarUrl: setAvatarUrl,
		setDmSounds: setDmSounds,
		setPlusMember: setPlusMember,
		setEnteredName: setEnteredName,
		setShowSignInModal: setShowSignInModal
	},
	dispatch
	);
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SignInButton);
