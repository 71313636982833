import React, { useState, useRef } from "react"
import { navigate } from 'gatsby';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import AppConfig from '../appconfig';
import UserMenu from "./userMenu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faBan, faEnvelope, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import anonUser from '../../static/anon_user.png';
import { FormattedMessage } from 'react-intl';


export default function UserListItem(props) {	

	const target = useRef(null);
	const [show, setShow] = useState(false);

	let imgSrc = props.avatarUrl;
	if (imgSrc == undefined || imgSrc == '') {
		imgSrc = anonUser;
	}

	return (
		<div>
			<li>
				<UserMenu show={show} setShow={setShow} username={props.username} target={target} langUrl={props.langUrl}
					addBlockedUsername={props.addBlockedUsername} removeBlockedUsername={props.removeBlockedUsername} placement={"bottom"} />

				<div ref={target} onClick={() => setShow(!show)}>

					<div className="d-flex bd-highlight">
						<div className="imgContainer">
							<img src={imgSrc} className="rounded-circle userImg" />
							<span className={'onlineIcon ' + props.status}></span>
						</div>
						<div className="userInfo">
							<span>{props.username}</span>
						</div>
					</div>

				</div>
			</li>
		</div>
	);
	
}



